@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';

.sort {
  cursor: pointer;
}

.up svg {
  transform: rotate(180deg);
}

.active {
  color: $grey
}
