@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.container {
    @include container;
    .header {
      @include top-header;
      h1{
        @include top-header-h1
      }
    }
    margin: 15px 0px 25px;
    
    .information {
        min-width: 510px;
        max-width: 550px;
        padding: 15px 10px 19px 32px;
        display: flex;
        font-size: 12px;
        position: relative;
  
        &::after {
          content: '';
          position: absolute;
          display: block;
          height: 176px;
          width: 1px;
          background: #EBEBEB;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
  
        .description {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          .description-info{
            margin-bottom: 11px;
            color: $dark-grey;
            span {
              display: block;
              width: 100%;
            }
            .description-name{
              display: inline-block;
              font-weight: bold;
              font-size: 30px;
              line-height: 25px;
              margin-bottom: 8px;
              margin-top: 33px;
              line-height: 41px;
            }
            .description-position{
              font-size: 14px;
              line-height: 24px;
              margin-bottom: 20px;
            }
          }
        }
      }
}

.btn{
    position: relative;
    top: 16px;
    height: 40px;
    width: 170px;
    button{
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
    }
}

.wrapBar {
    margin-bottom: 30px;
    display: flex;
    height: 40px;
    justify-content: space-between;
}

.list-filter {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: left;
}

.filter {
  // background-color: $grey;
//   height: 521px;
  // height: 100%;
  margin-left: 24px;
  position: sticky;
  top: 100px;
  z-index: 100;

  .pagination-container {
    // position: absolute;
    // bottom: 20px;
    margin-top: 50px;
    display: flex;
    justify-content: center
  }

  .pagination {
    @include pagination;
  }
  
  .pagination-item {
    @include pagination-item;
  }
  
  .pagination-active {
    @include pagination-active
  }
  
  .pagination-item {
    @include pagination-item-svg;
  }
  
  .pagination-disabled {
    @include pagination-disabled
  }
}

.empty-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 20px;
    line-height: 28px;
    color: $dark-grey;
  }
}

.canceled,
.work,
.interwiev,
.ready,
.created {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 90px;
  font-size: 13px;
  font-weight: 600;
  // line-height: 13.62px;
  text-align: center;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.created {
  color: $points_icon;
  background-color: $pink-light;
}
.ready {
  color: $blue;
  background-color: $light-blue;
}
.interwiev {
  color: $orange;
  background-color: $orange-light;
  width: 85px;
}
.work {
  color: $green;
  background-color: $light-green;
}
.canceled {
  color: $red;
  background-color: $light-red;
}

.task-count {
    color: $dark-grey;
    font-weight: 700;
    line-height: 26px;
    font-size: 16px;
    span {
        color: $green;
    }
}