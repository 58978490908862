@import '../../assets/style/colors';
@import "../../assets/style/variables";
@import "../../assets/style/mixins";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(115, 113, 111, 0.4);
  z-index:  10200;
  transition: all 1s;
  cursor: pointer;
}
.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10201;
  padding: 16px 25px 35px 32px;
  min-width: 360px;
  min-height: 500px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);

  @media screen and (max-width: 550px) {
    min-width: 80%;
  }

  animation: modal 0.6s ease-in-out;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}

.title {
  font-size: 1.3em;
  font-weight: normal;
}
.hidden{
  display: none;
}

.close {
  background: none;
  cursor: pointer;
  padding: 0;
}

.hidden-btn{
  background: none;
  svg{
    path{ stroke: $white}
  }
}

@keyframes modal {
  0% {
    top: -10%
  }

  50% {
    top: 55%
  }

  100% {
    top: 50%
  }
}
