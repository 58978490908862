@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';

.project-wrapper {
  display: flex;
  align-items: center;

}

.project-list {
  margin-top: 0;
  margin-bottom: 0;

  li {
    margin-top: 0;
    position: relative;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-top: 15px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: $light-blue;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $light-grey;
    }
  }
}

.project-image {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin-right: 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(55, 55, 237, 0.15);
  }
}

.project-name {
  color: $dark-grey;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

.project-manager {
  margin-top: 24px;
  font-size: 14px;
  line-height: 24px;

  span {
    font-weight: 700;
    margin-right: 5px;
    color: $blue;
  }
}

.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  height: 100%;
}

.empty-projects {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey;
  font-size: 20px;
}
