@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.generalInfoSection {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  margin-top: 20px;
  color: $dark-grey;
}

.generalInfoItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 20px 0;
  border-top: 1px solid $color-gray;

  &:first-child {
    border: none;
  }
}

.generalInfoText {
  font-size: 14px;
  font-weight: 600;
}

.generalInfoValue {
  color: $blue;
  font-size: 20px;
  font-weight: 700;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
