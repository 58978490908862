@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.container {
    @include container;
    .header {
      @include top-header;
      h1{
        @include top-header-h1
      }
    }
    margin: 15px 0px 25px;
    
}

.list-filter {
  display: flex;
  justify-content: space-between;
}

.filter {
  height: 521px;
  margin-left: 24px;
  position: sticky;
  top: 80px;
  // z-index: 100;

  .pagination-container {
    // position: absolute;
    // bottom: 20px;
    margin-top: 150px;
    display: flex;
    justify-content: center
  }

  .pagination {
    @include pagination;
  }
  
  .pagination-item {
    @include pagination-item;
  }
  
  .pagination-active {
    @include pagination-active
  }
  
  .pagination-item {
    @include pagination-item-svg;
  }
  
  .pagination-disabled {
    @include pagination-disabled
  }
}

.wrap-bar {
  position: relative;
  top: 16px;
  margin-bottom: 30px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  .back-btn {
      font-size: 14px;
      cursor: pointer;
      color: $blue;
      svg {
          position: relative;
          right: 5px;
          top: 3px;
      }
  }
}

.empty-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 20px;
    line-height: 28px;
    color: $dark-grey;
  }
}