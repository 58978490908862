@import '../../assets/style/colors';
@import '../../assets/style/variables';

.subTitle {
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 700;
  color: $dark-grey;
  text-align: left;
}

.fieldWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.inputContainer {
  display: flex;
  align-items: center;

  &::after {
    content: "%";
    font-size: 14px;
    font-weight: 600;
  }
}

.field {
  display: inline-block;
  height: 25px;
  width: 35px;
  margin-right: 3px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid $blue;
  border-radius: 10px;
}

.rangeWrapper {
  position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rangeInput {
  width: 100%;
  margin-bottom: 8px;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 4px;
  background: $red;
  border-radius: 5px;
  background-image: linear-gradient($green, $green);
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: $green;
  cursor: pointer;
  box-shadow: 0 0 2px 0 $dark-grey;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: #6ad49f50 0px 0px 0px 8px;
}

input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: #6ad49f50 0px 0px 0px 11px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    left 350ms cubic- bezier(0.4, 0, 0.2, 1) 0ms,
    bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.rangeSum1,
.rangeSum2 {
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  color: $green;
}

.rangeSum2 {
  right: 0;
  color: $red
}