@import '../../assets/style/colors';
@import "../../assets/style/variables";


.comments {
  width: 100%;
  text-align: left;

  span {
    color: $grey;
    font-size: 12px;
  }

  textarea {
    width: 100%;
    border: 1px solid $blue;
    border-radius: 10px;
    box-shadow: 0 0 20px $box-shadow;
    color: $dark-grey;
    padding: 5px 5px 5px 5px;
    font-size: 12px;
    outline: none;
    margin-top: 5px;
    resize: none;

    &:read-only {
      color: $grey;
      border-color: $grey;
      cursor: not-allowed;
      outline: 0 !important;
    }
    &.textarea-size-default {
      height: 150px;
    }
    &.textarea-size-sm {
      height: 108px;
    }
    &.textarea-size-xs {
      height: 69px;
    }
  }
}

.checkout-comment {
  width: 100%;
  margin-bottom: $size-sm;
  font-size: 12px;
  textarea {
    width: 90%;
    height: 72px;
    border: 1px solid $blue;
    border-radius: $size-sm;
    margin: 0 $size-md;
    padding: 1rem;
    color: $dark-grey;
  }
}

.invalid {
  textarea {
    border-color: $invalid-red;
    &::placeholder {
      color: $require-error-color;
    }
  }
}

.tooltip{
  background-color: $require-error-color !important;
  border-radius: 6px;
  padding: 3px 12px;
  font-size: 10px;
  line-height: 14px;
  z-index: 0;
  text-align: center;
  color: $white;
  border: 1px solid $light-grey;
  box-shadow: 0 0 20px $box-shadow;
  margin-top: 15px !important;
  &::after {
    border-bottom-color: $require-error-color !important;
  }
}

.label-required{
  position: relative;
}
.label-required::after{
  content: "*";
  top: -5px;
  right: -5px;
  position: absolute;
  color: $require-error-color;
}
