@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.container {
  @include container;
  position: relative;
  color: $dark-grey;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  padding-bottom: 56px;

  .header {
    @include top-header;

    h1 {
      @include top-header-h1
    }
  }
}

button {
  cursor: pointer;
}

.back-btn-wrapper {
  position: relative;
  top: 16px;
  margin-bottom: 30px;
  display: flex;
  height: 40px;
  justify-content: space-between;

  .back-btn {
    font-size: 14px;
    cursor: pointer;
    color: $blue;

    svg {
      position: relative;
      right: 5px;
      top: 3px;
    }
  }
}

.project-header {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  column-gap: 3%;
}

.project-header-img {
  min-width: 455px;
  flex-basis: 35%;
  height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-white;
  border: 1px solid $color-light-grey;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;
  flex-shrink: 0;

  img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
}

.project-header-text {
  display: flex;
  flex-flow: column;
  flex-basis: 55%;
  min-width: 300px;
  flex-shrink: 0;

  h1 {
    text-align: start;
    font-weight: 700;
    font-size: 30px;
    margin: 18px 0 24px 0;
  }

  &>div {
    font-weight: 400;
    font-size: 14px;
    text-align: start;
    display: flex;

    span {
      font-weight: 700;
      color: $blue;
    }

    &:nth-of-type(2) {
      margin-top: 20px;

      div {
        display: flex;
        flex-flow: column;
        row-gap: 4px;
      }
    }
  }
}

.project-main,
.project-client,
.project-files {
  background: $color-white;
  border: 1px solid $color-light-grey;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;
  padding: 28px 32px;
  text-align: start;
  margin-top: 24px;

  h2 {
    font-size: 20px;
    margin: 0;
  }
}

.project-main-wrapper {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.project-main-left,
.project-main-right {
  display: flex;
  flex-flow: column;
  row-gap: 28px;
  flex-basis: 38%;

  &>div {
    font-weight: 400;
    font-size: 14px;
    text-align: start;

    span {
      font-weight: 700;
      color: $blue;
    }
  }
}

.divider {
  margin: 1% 32px;
  width: 0;
  border: 1px solid #EBEBEB;
}

.project-main-right {
  row-gap: 60px;
  flex-basis: 56%;

  &>div {
    display: flex;
    flex-flow: column;
    row-gap: 4px;
  }
}

.project-client-wrapper {
  margin-top: 24px;
  display: flex;
  flex-flow: column;
  row-gap: 28px;

  &>div {
    font-weight: 400;
    font-size: 14px;
    text-align: start;

    span {
      font-weight: 700;
      color: $blue;
    }
  }
}

.project-files-wrapper {
  margin-top: 24px;
}

.deleted {

  .project-main,
  .project-client,
  .project-files {
    color: $color-gray;

    span {
      color: $color-gray;
    }
  }

  .project-files {
    *[class*='container'] {
      a {
        color: $color-gray;
      }
    }
  }
}

.project-toggle {
  position: relative;
  text-align: left;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  & > div {
    height: 68px;
    width: 86%;
  }
}

.project-team {
  text-align: start;

  h3 {
    margin: 40px 0;
  }
}

.project-toggle-vacancies {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  & > div {
    width: 85%;
  }
}
.currency-input {
  div[class*="wrapper"] {
    &::before {
      content: '$';
      position: absolute;
      font-size: 12px;
      color: $color-gray;
      top: 50%;
      transform: translateY(-50%);
      left: 11px;
    }

    input {
      padding-left: 22px;
    }
  }
}

.percent-input {
  div[class*="wrapper"] {
    &::before {
      content: '%';
      position: absolute;
      font-size: 12px;
      color: $color-gray;
      top: 50%;
      transform: translateY(-50%);
      left: 11px;
    }

    input {
      padding-left: 22px;
    }
  }
}

.time-input {
  div[class*="wrapper"] {
    &::before {
      content: url('../../../../../assets/images/time_icon.svg');
      position: absolute;
      height: 12px;
      width: 12px;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }

    input {
      padding-right: 32px;
    }
  }
}

.project-team-info form div:first-child {
  justify-content: initial;
}

.invoices-download {
  position: absolute;
  top: -52%;
  right: 0;
}
