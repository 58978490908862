@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.row {
  display: flex;
    label {
      font-size: $font-size-xs;
      color: $grey;
    }
}

.label {
  margin-bottom: 0.5em;
  white-space: nowrap;
}