@import '../../../../assets/style/colors';
@import '../../../../assets/style/variables';
@import '../../../../assets/style/mixins';

.container {
  @include container;
  position: relative;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .block-go-back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    font-size: 12px;
    margin-top: 26px;
    padding-bottom: 26px;
  }

  .go-back {
    background: none;
    color: $blue;
    padding: 0;
    cursor: pointer;
    svg {
      margin-bottom: -2.5px;
    }
    svg path {
      stroke: $blue;
    }
    .go-back-text {
      margin-left: 8px;
      font-size: 14px;
    }
  }

  .form-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .main-info {
    min-width: 30%;
    margin-right: 24px;
    padding: 28px 32px;
    background: $white;
    border: 1px solid $light-grey;
    box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
    border-radius: 14px;
  }

  .table {
    padding: 16px 0;
    background: $white;
    border: 1px solid $light-grey;
    box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
    border-radius: 14px;
    min-width: 200px;
    flex-grow: 1;
  }

  .table-shadow {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      border-radius: 14px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, #FFFFFF 100%);;
    }
  }

  .main-info-title {
    margin: 0;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $dark-grey;
  }

  .form-value {
    text-align: left;
    width: 100%;
    margin-top: 24px;
  }

  .form-checkbox {
    display: flex;
    margin-top: 24px;
    align-items: flex-start;

    div {
      margin-right: 0;
    }

    label[for="download"] {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .footer-btn-container {
    margin-top: 34px;
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 24px;
    }
  }

  .input-warp {
    margin-bottom: 0;

    div {
      margin-bottom: 0;
    }
  }

  .red-note {
    padding-left: 5px;
    display: inline;
    color: $invalid-red
  }

  .label-for-field {
    color: $grey;
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
    text-align: left;
    margin-top: 24px;
  }

  .dateInvalid {
    border: 1px solid $require-error-color !important;
  }

  div[data-for="picker"], div[data-id="tooltip"] + div {
    margin: 0 !important;
  }

}
