@import '../../assets/style/colors';
@import "../../assets/style/variables";

.next,
.prev {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    path {
      stroke: #fff;
    }
  }

  i>svg>path {
    fill: $blue;
  }
}

.swiper-button-disabled>i>svg>path {
  fill: $grey-disabled;
}

.prev {
  margin-right: 27px;
}

.swiper-nav-bts {
  position: absolute;
  bottom: -20px;
  display: flex;
  align-items: center;
}

.swiper-slide>div>p {
  margin: 20px 0 30px;
}
