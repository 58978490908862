@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";

.picture {
  position: relative;
  max-height: 335px;
  width: 100%;
  background-color: $light-grey;
  border-radius: 10px;
  margin-right: 32px;

  .crop {
    position: absolute;
    height: 200px;
    width: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    border-radius: 14px;
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  img {
    height: 335px;
  }

  .center {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    margin: 0;
  }

  .right {
    position: absolute;
    cursor: pointer;
    top: 41%;
    left: 48.5%;
    margin: 0;
    z-index: 10;
  }





  .info {
    padding: 20px 0;
    position: absolute;
    text-align: center;
    top: calc(50% + 15px);
    width: 100%;
    color: $grey;
    font-size: 12px;

    .pictureInfo {
      padding-top: 2px;
    }
  }
}

div[data-for="photo"] {
  text-align: center;
  &>div {
    margin-bottom: 0 !important;
  }
}

.invalid {
  border: 1px solid $require-error-color
}
