@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.backBattonWrapper {
  margin: 20px 0 20px 50px;
}

.settingsSection {
  @include profile-info-section;
  max-width: 800px;
  margin: 0 auto 20px;
}

.channelsWrapper {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0 10px 0;
  select{
    margin-left: 20px;
  }
}

.mainSettings,
.blockContainer,
.inputsContainer {
  display: flex;
  margin-bottom: 20px;
  padding-top: 20px;
}

.mainSettings {
  flex-direction: column;
  justify-content: flex-start;
}

.orderWrapper {
  display: flex;
  gap: 40px;
}

.blockContainer {
  border-top: 1px solid $color-gray;
}

.blockContainer,
.inputsContainer {
  column-gap: 40px;
  flex-wrap: wrap;
}

.mainSettings {
  gap: 20px;
}

.toggleWrapper {
  max-width: max-content;
  margin-bottom: 15px;
}

.inputsWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.select {
  display: flex;
  column-gap:40px;
  align-items: center;
}

.toggleContainer,
.blockTittle,
.inputsWrapper,
.select {
  width: 100%;
}

.blockTittle {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
  color: $color-blue;
}

.toggleContainer {
  margin: 10px;
  display: flex;
  justify-content: space-around;
}

.channelNameSelect {
  height: 30px;
  width: max-content;
  margin: 0 0 20px;
  padding: 0 10px;
  border: 1px solid $color-blue;
  border-radius: 10px;
}

.label {
  max-width: max-content;
  font-size: 12px;
  color: $color-gray;
}

.actionBtnsWrapper {
  display: flex;
  justify-content: center;
  gap: 25px;
}

.autoAveragingInputs{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  row-gap: 20px;
  column-gap: 20px;
}