@import '../../../../../assets/style/colors';

.button{
    padding: 4px 20px;
    font-size: 14px;
    line-height: 24px;
    height: fit-content;
}

.list-item{
    padding:20px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    background: $white;
    border:1px solid $light-grey;
    border-radius:14px;
    font-size: 18px;
    font-weight: 500;
    box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);

    &-body{
        display: flex;
        column-gap: 30px;
    }

    &-footer{
        padding-top:16px ;
        display: flex;
        justify-content: space-between;
        column-gap:16px;
        border-top: 1px solid $light-grey;
    }
}

.category{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 8px;
}

.category-label{
    font-size: 14px;
    color:$grey;
}

.value{
    display: flex;
    align-items:center;
    font-size: 20px;
    font-weight: 600;
    height: 40px;
}

.value-price{
    color:$green;
}

.avatar{
    width: 40px;
    height: 40px;
    border-radius:100%
}

.avatar-wrapper{
    display: flex;
    align-items:center;
    column-gap:12px;
}