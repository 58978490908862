.modal{
	min-height: 400px;
	.label{
			font-size: 14px;
	}
}

.modal-container{
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.modal-desc{
	margin-top: 10px;
	width: 300px;
	font-size: 14px;
	text-align: center;
	color: #9A9A9A;
}
.modal-err-desc{
	margin-top: 40px;
	width: 100%;
	text-align: center;
}

.modal-form{
	width: 100%;
}

.input-wrapper{
	margin-top: 20px;
}

.description-name {
	width: 100%;
	margin-bottom: 32px;
}

.input{
	height: 40px;
	font-size: 14px;
	line-height: 24px;
}

.button-wrapper{
	display: flex;
	justify-content: center;
}