@import '../../assets/style/colors';
@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.comment,
.commentDescription {
  @include profile-info-section;
  margin-bottom: 0;
  text-align: left;
  justify-content: space-between;
}

.title,
.userName,
.priceTitle,
.price {
  font-weight: 700;
}

.price,
.title {
  font-size: 20px;
}

.userName {
  font-size: 16px;
}

.userComment {
  font-size: 14px;
  overflow-wrap: break-word;
}

.priceTitle {
  font-size: 12px;
}

.price {
  color: $green;
}

.avatar {
  height: 40px;
  width: 40px;
  margin-right: 14px;
  border-radius: 50%;
}

.userInfoWrapper,
.userPhotoWrapper {
  display: flex;
  align-items: center;
}