@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  @include container;
  max-width: 1366px;
}

.wrapper {
  height: 100vh;
}

.sliderWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 60px;
}

.loginSlider {
  max-width: 1366px;
  width: 100%;
  padding-right: 32px;
  // max-width: 50%;
  margin: 0 !important;
}


.loader {
  position: absolute;
  display: flex;
  z-index: 20;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0px;
  align-items: center;
}

.spinnerOverlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.26);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
