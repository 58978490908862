@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';


.container {
  @include container;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
  .requestNav,
  .responseNav {
    display: flex;
    justify-content: center;
    text-align: left;
    .wrapper {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin: 34px 0;
      button {
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        color: $blue;
        svg {
          position: relative;
          right: 5px;
          top: 3px;
        }
      }
      .info {
        font-size: 12px;
        line-height: 18px;
        color: $dark-grey;
        span:nth-child(2) {
          font-weight: 700;
          color: $green;
        }
      }
    }
  }
  .requestNav {
    .wrapper {
      min-width: 1120px;
    }
  }
  .responseNav {
    .wrapper {
      min-width: 744px;
    }
  }
  .main {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: left;
    .requestForm,
    .responseForm {
      display: flex;
      justify-content: center;
      .columns {
        display: flex;
        justify-content: space-between;
        .column {
          h2 {
            font-size: $font-size-lg;
            line-height: 28px;
            color: $dark-grey;
            margin: 0 0 24px;
          }
          .date {
            width: 318px;
          }
          .time {
            width: 318px;
          }
          .invite {
            width: 360px;
            .board {
              height: 207px;
              background: $white;
              border: 1px solid $light-grey;
              border-radius: 10px;
              padding: 20px 16px;
              display: flex;
              flex-direction: column;
              overflow: auto;
              .row {
                display: flex;
                align-items: baseline;
                .line {
                  height: 100%;
                  align-self: center;
                  margin-right: 10px;
                  .horizontal {
                    width: 2px;
                    height: 100%;
                    background: $light-blue;
                    border-radius: 1px;
                  }
                }
                .date {
                  color: $dark-grey;
                  font-size: 14px;
                  margin-top: 10px;
                }
                .time {
                  display: flex;
                  flex-wrap: wrap;
                  flex: 0 0 70%;
                  justify-content: flex-end;

                  .item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $blue;
                    border-radius: 10px;
                    margin-left: 10px;
                    margin-bottom: 10px;

                    height: 26px;
                    width: 60px;
                    span {
                      font-size: 12px;
                      color: $white;
                    }
                  }
                }
              }
            }
          }
          .message {
            margin-top: 16px;
            .label {
              font-size: 12px;
              color: $grey;
              line-height: 18px;
            }
          }
        }
      }
    }
    .requestForm {
      .columns {
        min-width: 1120px;
      }
    }
    .responseForm {
      .columns {
        min-width: 744px;
      }
    }
    .requestButtons,
    .responseButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;
      .interviewsButton {
        display: flex;
        align-items: center;
        .interviewsAmount {
          margin-right: 20px;
          font-weight: bold;
          color: $dark-grey;
          span:nth-child(2) {
            color: $green;
          }
        }
      }
      .actionsButton {
        button:first-child {
          margin-right: 20px;
        }
      }
      .btn {
        color: $blue;
        font-size: $font-size-sm;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .requestButtons {
      min-width: 1120px;
    }
    .responseButtons {
      min-width: 744px;
    }
  }
}
