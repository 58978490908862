@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

@mixin method-selected {
  border: 1px solid #3737ED !important;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.5);
}

.container {
    @include container;
    .header {
      @include top-header;
      .title {
        @include top-header-h1
      }
    }
    margin: 15px 0px 25px;
}

.choose-exchange {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;

  .title {
    margin-bottom: 10px;
  }

  .description {
    width: 480px;
    margin-bottom: 32px;
  }
  .methods {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 50px;

    .methods-item {
      width: 360px;
      height: 180px;
      margin: 0px 20px;
      text-align: center;
      border-radius: 10px;
      border: 1px solid #EBEBEB;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .methods-item:hover {
      @include method-selected;
      cursor: pointer;
    }

    .shadow {
      @include method-selected;
    }
  }
}

form {
  p {
    position: inherit;
    padding-top: 0px;
  }
}