@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.container {
    @include container;
    .header {
      @include top-header;
      h1{
        @include top-header-h1
      }
    }
    margin: 15px 0px 25px;
    
    .information {
        min-width: 510px;
        max-width: 550px;
        padding: 15px 10px 19px 32px;
        display: flex;
        font-size: 12px;
        position: relative;
  
        &::after {
          content: '';
          position: absolute;
          display: block;
          height: 176px;
          width: 1px;
          background: #EBEBEB;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
  
        .description {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          .description-info{
            margin-bottom: 11px;
            color: $dark-grey;
            span {
              display: block;
              width: 100%;
            }
            .description-name{
              display: inline-block;
              font-weight: bold;
              font-size: 30px;
              line-height: 25px;
              margin-bottom: 8px;
              margin-top: 33px;
              line-height: 41px;
            }
            .description-position{
              font-size: 14px;
              line-height: 24px;
              margin-bottom: 20px;
            }
          }
        }
      }
}

.btn{
    position: relative;
    top: 16px;
    height: 40px;
    width: 170px;
    button{
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
    }
}

.wrapBar {
    margin-bottom: 30px;
    display: flex;
    height: 40px;
    justify-content: space-between;
}

.list-filter {
  display: flex;
  justify-content: space-between;
}

.filter {
  height: 521px;
  margin-left: 24px;
  position: sticky;
  top: 100px;

  .pagination-container {
    margin-top: 150px;
    display: flex;
    justify-content: center
  }

  .pagination {
    @include pagination;
  }
  
  .pagination-item {
    @include pagination-item;
  }
  
  .pagination-active {
    @include pagination-active
  }
  
  .pagination-item {
    @include pagination-item-svg;
  }
  
  .pagination-disabled {
    @include pagination-disabled
  }
}

.empty-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 20px;
    line-height: 28px;
    color: $dark-grey;
  }
}

.loadingWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding-bottom: 30vh
}