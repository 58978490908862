@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.slide {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 2px;
}

.slideWrapper {
  margin-right: 40px;
}

.slideTitle {
  font-size: 40px;
  font-weight: 700;
  max-width: 545px;
}

.slideDescription {
  font-size: 14px;
  line-height: 24px;
  max-width: 550px;
}

.slideIconsWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 550px;
  min-width: 380px;
  margin-top: 30px;
  gap: 20px;
}

.slideIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}

.slideIconSecond {
  border-left: 1px solid $light-grey;
  border-right: 1px solid $light-grey;
}

.slideIconText {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 92px;
}
