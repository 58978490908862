@import '../../assets/style/colors';
@import '../../assets/style/variables';

.back-btn {
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: $blue;

  svg {
    margin-right: 6px;
  }
}

.back-btn,
.back-btn svg {
  display: flex;
  align-items: center;
}