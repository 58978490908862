@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import '../../../assets/style/mixins';

.container {
  @include container;
  position: relative;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .content-container {
    display: flex;
    justify-content: space-between;
  }

  .form-column-container {
    width: 100%;
    text-align: left;
    color: $grey;
    font-size: 12px;

    .form-row-flex-container {
      display: flex;
      flex-direction: column;
      padding: 0 0 20px;



      .input-categoryId {
        width: 45%;
        border-radius: 6px;
      }

      .input-price{
        width: 50%;
      }

      .input-people {
        width: 45%;
      }
    }
  }

  .emptyPicture {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 335px;
    width: 48%;
    background-color: $light-grey;
    border-radius: 10px;
    z-index: 10;
  }

  .footer-btn-container {
    text-align: right;
    padding: 16px 0;

    button {
      margin-left: 20px;
      width: 210px;
      height: 40px;
    }
  }
}

.invalid {
  border: 1px solid $invalid-red;
}

.block-go-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  font-size: 12px;
  margin-top: 26px;
  padding-bottom: 26px;
}

.red-note {
  padding-left: 5px;
  display: inline;
  color: $invalid-red
}

.form-row-flex-container > div > div > div:first-child {
  margin-bottom: 0 !important;
}

.go-back {
  background: none;
  color: $blue;
  padding: 0;
  cursor: pointer;
  svg {
    margin-bottom: -2.5px;
  }
  svg path {
    stroke: $blue;
  }
  .go-back-text {
    margin-left: 8px;
    font-size: 14px;
  }
}

.info-description {
  margin-top: 25px;

  div>div>div {
    margin-bottom: 6px !important;
  }

  textarea {
    height: 238px !important;
  }
}

.main-information, .infoes-block {
  width: 100%;
  padding: 28px 32px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;

  textarea {
    height: 114px !important;
  }

  h2 {
    text-align-last: left;
    font-size: 20px;
    line-height: 28px;
  }

  .form-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .left-column, .right-column {
    width: 100%;
  }

  .left-column {
    margin-right: 32px;
  }
}

.input-text {
  text-align: left;
}

.two-columns {
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
}

.left-column-block, .right-column-block {
  margin-top: 32px;
  text-align: left;
  div>div>div {
    margin-bottom: 0 !important;
    label {
      margin-bottom: 0.5em;
      display: block;
    }
  }

  &:first-child {
    margin-top: 12px;
  }
}

.client-information {
  margin-top: 24px;
}

.file-upload {
  // opacity: 0;
  position: absolute;
  // z-index: -1;
  // width: 10px;
}

.add-files-block {
  margin-top: 24px;
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .add-file-arria {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: dashed 1px $blue;
    border-radius: 8px;
    margin-top: 13px;
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
  .add-image-arria {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    width: 455px;
    // border: dashed 1px $blue;
    background: #EBEBEB;
    border-radius: 8px;
    margin-top: -24px;
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
}

.label-for-field {
  color: $grey;
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}

textarea {
  margin-top: 0 !important;
}

.modal {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    position: absolute;
    right: 15px;
    top: 25px;
  }
}
