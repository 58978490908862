@import '../../../../../../../assets/style/colors';
.executorWrapper {
  margin-top: 24px;
}

.executorTitle {
  font-size: 18px;
  text-align: left;
}

.executorInner {
  display: flex;
  align-items: center;
}

.executorNameAvatar {
  display: flex;
  align-items: center;

  img {
    display: block;
  }

  p {
    font-size: 14px;
    margin-left: 7px;
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 16px;
  width: 16px;
  background-color: none;
  border: 1px solid $blue;
  border-radius: 3px;
}

.container input:checked ~ .checkmark {
  background-color: transparent;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 4px;
  height: 9px;
  border: solid #3737ED;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}

.container:hover input ~ .checkmark {
  transition: 0.3s ease-in-out;
}

.container:hover input ~ .checkmark {
  background-color: rgba($blue, 0.2);
}

.avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
    border: 1px solid $orange;
}
