@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.spinner {
  display: flex;
  justify-content: center;
  padding-top: 200px;
}

.idenfyImg {
  filter: grayscale(100%);
}

.choose-verifi {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    margin-bottom: 10px;
  }

  p {
    width: 480px;
    margin-bottom: 32px;
  }

  .methods {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 50px;

    div {
      width: 360px;
      height: 180px;
      margin: 0px 20px;
      text-align: center;
      border-radius: 10px;
      border: 1px solid #EBEBEB;
      display: flex;
      align-items: center;
      justify-content: center;
      // box-sizing: border-box;
    }

    .shadow {
      border: 1px solid #3737ED !important;
      // box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 20px rgba(51, 51, 51, 0.5);
    }
  }
}
