@import '../../../../../../../assets/style/colors';
@import "../../../../../../../assets/style/variables";
@import "../../../../../../../assets/style/mixins";

.wrapper {
  background: $white;
  border: 1px solid $light-grey;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;
  margin-top: 16px;
  padding: 21px 24px 15px 24px;
  color: $dark-grey;
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.confirm-button {
  width: max-content !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  margin-top: 16px;
  max-width: 1420px;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.deadline {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.date-icon {
  margin-right: 5px;
  margin-top: 4px;
}

.date {
  margin-left: 5px;
}

.price {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: $green;
}

.cancelled {
  color: $orange;
  background-color: $orange-light;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}
