@import '../../../../assets/style/colors';
@import "../../../../assets/style/variables";
@import "../../../../assets/style/mixins";


.bonuses-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.history {
  color: $grey;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  i {
    display: block;
    margin-right: 6px;
    svg {
      width: 13px;
    height: 13px;
    }
  }
}

.bonuses-main-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.bonuses-trl {
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  color: $orange;
  span {
    font-size: 16px;
    line-height: 26px;
  }
}

.bonuses-form {
  display: flex;
  align-items: center;
}

.form-value {
  margin-bottom: 0 !important;
  width: 190px;
  margin-right: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.bonuses-withdraw {
  min-width: 130px;
  height: 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
