@import '../../../assets/style/variables';
@import '../../../assets/style/colors';

.table {
  width: $width-lg;
  background: $white;
  border: $border-table;
  box-shadow: $box-shadow-table;
  border-radius: $border-radius-table;
}

.header {
  font-weight: bold;
  font-size: 1rem;
  line-height: $line-height-header;
  color: $blue;

  .headerTr {
    width: $width-lg;
    display: flex;
    justify-content: space-between;
    border-bottom: $border-bottom-tr;
    padding: $padding-header;

    .headerTh {
      padding: $padding-header-th;
      text-align: left;
    }
  }
}


.body {
  min-height: $min-height-body;
  max-height: $max-height-body;
  padding-bottom: $padding-bottom-body;
  position: relative;

  &>div {
    will-change: auto !important;

    &>div {
      overflow-x: hidden !important;
    }
  }
}

.bodyTr {
  display: flex;
  justify-content: space-between;
  padding: $padding-body-tr;
  border-bottom: 1px solid $light-grey;

  &:hover {
    background: $tableHover;
  }
}

.bodyTd {
  padding: $padding-body-td;
  text-align: left;
  display: flex !important;
  align-items: center;
}

.bodyTdText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.no-data {
  position: absolute;
  top: 40%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}


.teamTable {
  position: relative;
  min-width: $width-lg;
  background: $white;
  border: $border-table;
  box-shadow: $box-shadow-table;
  border-radius: $border-radius-table;
  overflow: auto;
  //& > div {
  //  overflow: visible !important;
  //}
     &>div:last-child {
       &>div {
         &>div {
           overflow: visible !important;
         }
       }
     }
}

.bodyTeamAdmin {
  min-height: $min-height-body;
  max-height: $max-height-body;
  padding-bottom: $padding-bottom-body;
  position: relative;
}

.headerTrTeam,
.bodyTrTeam {
  padding-left: 20px!important;
  padding-right: 0!important;
}

.headerTrTeam {
  width: $width-lg;
  display: flex;
  border-bottom: $border-bottom-tr;

  .headerTh {
    padding: $padding-header-th;
    text-align: left;
  }
}

.bodyTrTeam {
  width: $width-lg;
  display: flex;
  border-bottom: 1px solid $light-grey;

  &:hover {
    background: $tableHover;
  }
}
