@import "../../../../../assets/style/variables";

.project-wrapper {
  flex: 1 0 21%;
}
.project-content {
  width: 264px;
  height: 270px;
  border: 1px solid $light-grey;
  border-radius: 14px;
  position: relative;
  overflow: hidden;
  padding-bottom: 88px;
  margin: 0 auto;
  
  .project-avatar {
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .project-title {
    width: 100%;
    height: 88px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba($color: $color-dark-blue, $alpha: 0.7);
    border-radius: 14px;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all .5s ease;
    div {
      transition: all .5s ease;
    }
    .nav-link {
      width: 216px;
      height: 32px;
      transform: translateY(160px);
      transition: transform .5s ease;
      pointer-events: none;
      position: absolute;
      bottom: 24px;
      button {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        height: 100%;
      }
    }
  }

  &:hover {
    .project-title {
      height: 100%;
      div {
        margin-top: -100px;
      }
      .nav-link {
        pointer-events: all;
        transform: translateY(0);
      }
    }
  }

}