@import '../../../../assets/style/colors';
@import '../../../../assets/style/variables';
@import '../../../../assets/style/mixins';


.container {
  @include container;
  padding-bottom: 56px;
  .header {
    @include top-header;
    h1 {
      @include top-header-h1;
      cursor: pointer
    }
  }

  .mainInfo {
    @include profile-info-section;
  }

  form {
    margin: 0 auto;
  }
  .mainInfoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    gap: 32px;

    .FieldFactory_row {
      width: 100%;
    }
  }


  .createVacancyContainer {
    margin: 0 auto;

  }
  .mainInfoWrap {
    display: flex;
    gap: 32px;
    align-items: center;
    & > div {
      width: 100%;
    }
  }

  .requirementsInfo {
    @include profile-info-section;
  }

  .requirementsInfoTopContainer {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 32px;
  }

  .requirementsInfoBottomContainer {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 27px;
  }

  .buttonsWrapper {
    text-align: right;
    button:first-child {
      margin-right: 24px;
    }
  }

  .backButton {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 34px 0;
    color: #3737ED;
    cursor: pointer;
    font-weight: 600;
  }

  .mainInfoColumn {
    width: 50%;
  }

  position: relative;
  margin: 15px 0px 25px;
  color: $dark-grey;
}

.modal {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    position: absolute;
    right: 15px;
    top: 25px;
  }
}

