.task-cancelled-pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  .rc-pagination-item {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-right: 19px;
    cursor: pointer;
  }

  .rc-pagination-prev {
    margin-right: 20px;
  }

  .rc-pagination-next {
    margin-left: 6px;
  }

  .rc-pagination-item-active {
    background: #149B58;
    border-radius: 6px;
    color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rc-pagination-jump-next, .rc-pagination-jump-prev {
    margin-right: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    cursor: pointer;
  }

}
