@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";

.input-name {
  width: 50%;
}


.input {
  margin-top: 7px;
}

.input-warp {
  width: 100%;
  margin-bottom: 0;
}
