@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.container {
  @include container;
  position: relative;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .wrapBar {
    margin-bottom: 30px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: baseline;
  }
  margin: 15px 0px 25px;
}

.buttons {
  display: flex;
  align-items: center;
}

.history {
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: none;
  margin-right: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;

  i {
    display: block;
    margin-right: 6px;
    svg {
      width: 13px;
    height: 13px;
    }
  }
}
