@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.container {
    @include container;
    .header {
      @include top-header;
      h1{
        @include top-header-h1;
        cursor: pointer;
        .back-arrow {
          position: relative;
          bottom: -7px;
        }
      }
    }
    height: calc(100vh - 100px);
    margin: 15px 0px 0px;
    box-sizing: border-box;
}

.wrapBar {
    margin-bottom: 30px;
    display: flex;
    height: 40px;
    justify-content: space-between;
}

.date {
  width: 318px;
  margin-right: 17px;
}

.planing {
  display: flex;
  flex-wrap: wrap;
}

/*
This is the Material Design theme for FullCalendar Weekly Agenda view
Creation Date: Aug 19th 2015
Author: Jacky Liang
Version: FullCalendar 2.4.0
Tested Using the Following FC Settings:
    editable: false,
    handleWindowResize: true,
    weekends: false, // Hide weekends
    defaultView: 'agendaWeek', // Only show week view
    header: false, // Hide buttons/titles
    minTime: '07:30:00', // Start time for the calendar
    maxTime: '22:00:00', // End time for the calendar
    columnFormat: {
        week: 'ddd' // Only show day of the week names
    },
    displayEventTime: true,
    allDayText: 'Online/TBD'
Note: This has NOT been tested on Monthly or Daily views.
Colors: Use the following - https://www.google.com/design/spec/style/color.html#color-color-palette
        at the 700 level. An opacity of 0.65 is automatically applied to the
        700 level colors to generate a soft and pleasing look.
        Color were applied to each event using the following code:
        events.push({
            title: 'This is a Material Design event!',
            start: 'someStartDate',
            end: 'someEndDate',
            color: '#C2185B'
        });
*/
/* Remove that awful yellow color and border from today in Schedule */
.fc-state-highlight {
  opacity: 0;
  border: none;
}

/* Styling for each event from Schedule */
.fc-time-grid-event.fc-v-event.fc-event {
  border-radius: 4px;
  border: none;
  padding: 5px;
  opacity: .65;
  left: 5% !important;
  right: 5% !important;
}

/* Bolds the name of the event and inherits the font size */
.fc-event {
  font-size: inherit !important;
  font-weight: bold !important;
}

/* Remove the header border from Schedule */
.fc td, .fc th {
  border-style: none !important;
  border-width: 1px !important;
  padding: 0 !important;
  vertical-align: top !important;
}

/* Inherits background for each event from Schedule. */
.fc-event .fc-bg {
  z-index: 1 !important;
  background: inherit !important;
  opacity: .25 !important;
}

/* Normal font weight for the time in each event */
.fc-time-grid-event .fc-time {
  font-weight: normal !important;
}

/* Apply same opacity to all day events */
.fc-ltr .fc-h-event.fc-not-end, .fc-rtl .fc-h-event.fc-not-start {
  opacity: .65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

/* Apply same opacity to all day events */
.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
  opacity: .65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

/* Material design button */
.fc-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  border: 0;
  padding: 0 6px;
  margin: 6px 8px;
  letter-spacing: .01em;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  -webkit-transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);
  transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);
}

.fc-button:hover {
  background-color: rgba(158,158,158,0.2);
}

.fc-button:focus, .fc-button:hover {
  text-decoration: none;
}

/* The active button box is ugly so the active button will have the same appearance of the hover */
.fc-state-active {
  background-color: rgba(158,158,158,0.2);
}

/* Not raised button */
.fc-state-default {
  box-shadow: None;
}

.calendarSpinner {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 700px;
}

.info {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  margin-top: 38px;
}

.infoTitle {
  font-size: 20px;
}

.less {
  color: $green;
}

.correct {
  color: $red;
}

.infoButtons {

  button {
    cursor: pointer;
  }
  button:first-child {
    margin-right: 20px;
  }
}
