@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';

// h1, h2, h3, h4, h5, h6, p {
//   margin: 0;
// }

// body {
//   background: rgba(0, 0, 0, 0.55);
// }


.invoiceWrapper {
//  position: absolute;
//  left: -9999px;
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  body {
    background: rgba(0, 0, 0, 0.55);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.55);
  z-index: 999;
  transition: all 500ms;
  cursor: pointer;
  overflow-y: scroll ;
  max-height: 100vh;
}

.container {
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  visibility: hidden;
  opacity: 0;
  max-width: 670px;
  padding-bottom: 56px;

  &.active {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
}



.invoice {
  padding: 30px;
  display: flex;
  gap: 80px;
  justify-content: space-between;
  padding-bottom: 0;
  &__modal {
    width: 595px;
  }

  &__container {
    padding: 30px;
  }

  &__title {
    font-weight: 700;
    color: $dark-grey;
    font-size: 32px;
    line-height: 24px;
    letter-spacing: 0.01em;
    margin: 0;
  }

  &__left {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  &__companyInfo {
    display: flex;
    flex-direction: column;
    margin-top: 57px;
    line-height: 16px;
    font-size: 10px;
  }


  &__companyInfoName {
    text-transform: uppercase;
  }


  &__recipient {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    font-size: 10px;
  }

  &__recipientOwner {
    font-weight: 700;
    text-transform: uppercase;
  }

  &__number {
    color: $grey;
    font-size: 12px;
    line-height: 20px;
  }

  &__price {
    margin-top: 86px;
  }

  &__right {
    display: flex;
    flex-direction: column;
  }

  &__priceItem {
    display: flex;
    justify-content: space-between;
  }

  &__priceInfo {
    color: $grey;
    text-align: right;
    font-size: 10px;
    line-height: 16px;
  }

  &__priceData {
    color: $dark-grey;
    line-height: 16px;
    font-weight: 700;
    font-size: 10px;
  }

  &__priceItemTotal {
    margin-top: 10px;
    margin-right: -30px;
    margin-left: -10px;
    padding: 10px;
    background: #F9F9FF;
    width: 240px;
    display: flex;
    justify-content: space-between;
    span {
      color: $green;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__priceItemProject {
    span {
      color: $black;
    }
  }

  &__priceAmount {
    margin-right: 20px;
  }

  &__table {
      border-spacing: 0;
      width: 100%;
      margin-top: 86px;
      font-size: 10px;
      border-collapse: collapse;
      td {
        padding: 12px 40px;
        text-align: center;
        border-right: 1px solid #BBBBFF;
      }

      th {
        border-right: 1px solid #BBBBFF;
      }

      td:last-child, th:last-child {
        border: none;
      }

      tr {
        border-bottom: 1px solid #BBBBFF;
      }
  }

  &__tableProject {
    word-break: break-word;
    border-bottom: none;
    margin-top: 24px;
    td {
      padding: 0;
      height: 50px;
    }

    tr {
      border-bottom: 1px solid #BBBBFF;
      &:last-child {
        border-bottom: none;
      }
    }

    th {
      padding: 0;
      height: 40px;
    }
  }

  &__tableTitle {
    padding: 40px;
    color: $dark-grey;
    background: #F9F9FF;
    padding: 12px 40px;
    text-align: center;
  }

  &__amount {
    text-align: right;
  }

  &__amountName {
    color: $grey;
    font-size: 10px;
    line-height: 20px;
    margin-right: 19px;
  }

  &__discount {
    justify-content: flex-end;
    gap: 30px;
  }

  &__notes {
    text-align: left;
    padding: 0 30px;
    font-size: 10px;
    line-height: 16px;
  }

  &__notesTitle {
    font-weight: 700;
  }

  &__priceItem + .invoice__priceItem {
    margin-top: 12px;
  }
}
