@import '../../assets/style/colors';
@import '../../assets/style/variables';

.fieldDescription {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 22px;
  color: $grey;
  cursor: default;
}

.field {
  display: flex;
  align-items: center;
  height: 30px;
  min-width: 100px;
  padding: 0 10px;
  border: 1px solid $blue;
  border-radius: 10px;
  cursor: pointer;
}

.tokenImg {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.tokenName {
  margin-right: 3px;
  font-size: 14px;
  color: $dark-grey;
}

.red-note {
  margin-left: 5px;
  color: $require-error-color;
}

.disabled {
  display: none;
}

.opacity {
  cursor: default;
  border: 1px solid #9A9A9A !important;
  div {
    opacity: 0.5 !important;
  }
}