@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  @include container;
  position: relative;
  color: $dark-grey;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  .header {
    @include top-header;
    h1 {
      @include top-header-h1
    }
  }
}

button {
  background-color: transparent;
  cursor: pointer;
}

.filter {
  margin-top: 26px;
}

.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.tabs > div {
  margin-top: 0;
}

.links {
  button {
    &:first-child {
      color: $blue;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-right: 24px;
      i, svg {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }
}

.projects {
  margin: 24px 0 56px 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 22px;
  flex-grow: 1;
}

.pagination {
  margin: 0 auto 56px auto;
  & > ul {
    justify-content: center;
    align-items: center;
  }
}

.pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  margin: 0 12px;
  color: $dark-grey;
  text-decoration: none;
}

.pagination-active {
  color: $white;
  background-color: $green;
}

.no-data {
  margin: 0 auto;
  align-self: center;
}