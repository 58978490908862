@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";

.filters-wrapper {
.inputs {
  display: flex;
}

.primary-form{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
  .input-warp {
    margin-bottom: 0;
    width: 280px;
    text-align-last: left;

    &:first-child {
      margin-right: 24px;
    }
  }

  .remove-filter {
    display: flex;
    align-items: center;
    color: $red;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    i {
      display: block;
      margin-left: 8px;
      margin-top: 6px;
    }
  }
}
