@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

$block-margin-bottom: 24px;

.container{
  @include container;
  display: flex;
  padding-top: 24px;
  padding-bottom: 64px;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
}

.section {
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  text-align: left;
  padding: 28px 32px 36px 32px;
  border: 1px solid $light-grey;
  border-radius: 14px;
  background-color: $white;
  font-size: $font-size-sm;
  h3 {
    font-size: $font-size-lg;
    margin-top: 0;
    margin-bottom: 24px;
  }
  textarea {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    padding: 6px 12px;
  }
}

.nav,
.avatar-block,
.contact-info-block,
.private-info-block,
.financial-info-block,
.add-files-block {
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
}

.nav {
  width: 264px;
  margin-bottom: auto;
  text-align: left;
  margin-right: 24px;
  padding: 16px 32px 19px 32px;
  border: 1px solid $light-grey;
  border-radius: 14px;
  background-color: $white;
    [class^="ToggleBar_togglebar"] {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      height: unset;
    }
    [class^="ToggleBar_togglebar"] div {
      // Uncomment the prop to enable more tabs
      // margin-bottom: 10px;
      font-size: $font-size-sm;
    }
    [class^="ToggleBar_togglebar"] div label {
      font-size: $font-size-sm;
      font-weight: 700;
      padding: 0;
      text-align: left;
      border-bottom: none;
      color: $color-black;
    }
    [class^="ToggleBar_togglebar"] div input[type="radio"]:checked + label::before {
      content: ' \25CF';
      padding-right: 10px;
    }
}

.main {
  min-width: 832px;
  width: 76%;
}

.row {
  display: flex;
    label {
      font-size: $font-size-xs;
      color: $grey;
    }
}

.label {
  margin-bottom: 0.5em;
}

.margin-right {
  margin-right: 32px;
}

.avatar-block,
.contact-info-block,
.private-info-block,
.financial-info-block,
.add-files-block {
  text-align: left;
  padding: 28px 32px 36px 32px;
  border: 1px solid $light-grey;
  border-radius: 14px;
  background-color: $white;
  font-size: $font-size-sm;
}

.avatar-block {
  margin-bottom: $block-margin-bottom;
  .description-name {
    width: 250px;
  }
    .description-position {
      width: 300px;
      margin-right: 32px;
    }
    .description-level {
      width: 180px;
    }
    .img {
      position: relative;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      margin-right: 24px;
        input {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }
        .plus {
          width: 129px;
          position: absolute;
          top: 38%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 4px;
          .addFoto {
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: $color-gray;
          }
        }
        .editBtn {
          position: absolute;
          right: 0;
          top: 75%;
        }
    }
      .notImg {
        border: 2px dashed $blue;
      }

    h2 {
      font-size: 30px;
      vertical-align: middle;
    }
  .avatarRow {
    margin-bottom: 24px;
    justify-content: space-between;
    .avatarColumn {
      display: flex;
      align-items: center;
    }
    .visibilityColumn {
      .anonymous {
        margin-bottom: 14px;
      }
    }
  }
}

.contact-info-block,
.private-info-block,
.financial-info-block,
.add-files-block {
  h3 {
    font-size: $font-size-lg;
    margin-top: 0;
  }
}

.contact-info-block{
  margin-bottom: $block-margin-bottom;
    .description-email {
      min-width: 300px;
      width: 41%;

      .disabled-input {
        border: 1px solid $grey-disabled;
        input {
          cursor: not-allowed;
          color: $grey;
        }
      }
    }
    .description-phone {
      min-width: 202px;
      width: 26%;
    }
    .description-location {
      min-width: 202px;
      width: 26%;
    }
    .description-sociale {
      min-width: 235px;
      width: 31%;
    }
}

.private-info-block {
  margin-bottom: $block-margin-bottom;
    .description-DOB{
      width: 202px;
      margin-right: 32px;
    }
    .description-hometown {
      width: 202px;
      // margin-top: 0.286em;
    }
    .description-hobby,
    .description-life-goal {
      width: 100%;
      min-height: 98px;
      margin-bottom: 32px;
      textarea {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 18px;
        padding: 6px 12px;
      }
    }
    .description-lifePriorities {
      min-width: 444px;
      width: 81%;
      margin-right: 32px;
    }
    .description-marital-status {
      min-width: 140px;
      width: 19%;
    }
    .description-amount-of-children{
      width: 120px;
      margin-right: 32px;
      margin-bottom: 32px;
      .label {
        margin-bottom: 6px
      }
    }
    .description-university {
      min-width: 444px;
      width: 58%;
      margin-right: 32px;
    }
    .description-academic-degree {
      min-width: 292px;
      width: 39%;
      .label {
        margin-bottom: -7px
      }
    }
    .description-DOBchildren {
      width: 141px;
      margin-right: 32px;
      margin-bottom: 32px;
    }
    [class^="react-datepicker__input-container"] input {
      margin-top: 0;
    }
}

.add-files-block {
  margin-top: 24px;
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .add-file-arria {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: dashed 1px $blue;
    border-radius: 8px;
    margin-top: 13px;
    label {
      cursor: pointer;
    }
  }
}

.btnContainerClass {
  text-align: right;
  margin-top: 32px;
    button {
      width: 172px!important;
      margin-left: 24px;
      font-size: $font-size-sm;
    }
}

.department {
  min-width: 344px;
  width: 45%;
  margin-right: 32px;
}

.salary {
  min-width: 170px;
  width: 22%;
  margin-right: 32px;
}

.salary-change-date {
  min-width: 194px;
  width: 25%;
}

.salary-change-date,
.first-working-day,
.probation-end-date
 {
  [class^="react-datepicker__input-container"] input {
    margin-top: 0;
  }
}

.working-hours-per-day {
  min-width: 132px;
  width: 18%;
  margin-right: 32px;
}

.first-working-day,
.probation-end-date,
.english-level {
  min-width: 180px;
  width: 23%;
}

.first-working-day,
.english-level {
  margin-right: 32px;
}

.previous-job {
  min-width: 300px;
  width: 36%;
}

div[class^="Select_option_"]{
  background-position-x: 10px;
}
.description-lifePriorities div[class$="-control"]{
  height: unset;
}
.description-marital-status div[class$="-control"]{
  height:32px;
}

.loading-modal {
  min-height: 0;
  padding: 10px 32px 24px;

  p {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }
}

.data-saved-container div {
  width: fit-content;
  margin: 0 auto;
  button {
    width: 172px!important;
    font-size: $font-size-sm;
  }
}

.formFooterSection {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.checkboxContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.verificationContainer {
  height: 500px;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  text-align: left;
  padding: 28px 32px 36px 32px;
  border: 1px solid $light-grey;
  border-radius: 14px;
  background-color: $white;
  font-size: $font-size-sm;
}

.addProjectBtn {
  display: flex;
  cursor: pointer;
  justify-content: center;
  margin-top: 40px;
}

.delete_vacancy {
  margin-left: auto;
  cursor: pointer;
  margin-top: 45px;
}

.project__wrapper {
  display: flex;
  & > div {
    flex-basis: 98%;
  }
}

.financial-info-block {
  .description-bank{
    min-width: 150px;
  }
}
