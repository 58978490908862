@import '../../../assets/style/variables';

.wrapper {
  width: 276px;
  min-height: 520px;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;
  padding: 20px 24px 30px;
  display: flex;
  flex-direction: column;
}

.container {
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}

.inputWrapper {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 12px;
  }

  > p {
    font-size: 12px;
    line-height: 18px;
    color: #9A9A9A;
    margin: 0;
    width: 100%;
    text-align: left;
  }
}

.budget {
  > p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    padding-right: 18px;
  }
  
  .budgetInput {
    width: 65px;
  }

  .currency {
    margin-top: -7px;
    margin-left: 15px;
    width: 60px;
  }
}

.field {
  display: flex;
  position: relative;
}

.dash {
  width: 8px;
  height: 1px;
  background: #333333;
  margin: 14px 8px;
}

.input {
  > p {
    margin-bottom: 5px;
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  align-items: center;
}

.acceptBtn {
  width: 140px;
  height: 32px;

  button {
    width: 140px;
    height: 32px;
  }
}

.cancel {
  height: 32px;

  button {
    margin: 0;
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: $color-crimson;
      font-weight: 700;
      margin-left: 6px;
      padding-top: 2px;
    }
    &:hover span {
      color: $discharge-btn-hover;
    }
    &:active span {
      color: $discharge-btn-active;
    }
  }
}
