@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.container {
  @include container;
  position: relative;
  color: $dark-grey;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
}

.wrap-bar {
  position: relative;
  top: 16px;
  margin-bottom: 30px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  .back-btn {
    font-size: 14px;
    cursor: pointer;
    color: $blue;
    svg {
      position: relative;
      right: 5px;
      top: 3px;
    }
  }
}

button {
  background-color: transparent;
}

.color-price {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}
.color-price-red {
  color: $red;
}
.color-price-green {
  color: $green;
}

.table {
  font-size: 14px;
  line-height: 24px;
}

.sign {
  display: inline-block;
  text-align: center;
  width: 10px;
  margin-left: -16px;
}

.token {
  font-weight: 700;
  display: flex;
  align-items: center;
}

.token-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 6px;
  i, svg {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

.task {
  text-decoration: underline;
}