@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.info-section {
  @include profile-info-section;
}

.career-wrapper {
    display: flex;
    flex-direction: column;

    & > div:first-of-type {
      display: flex;
      justify-content: space-between;
    }

    .career-info {
      min-width: 735px;
      margin-right: 24px;
      width: 65%;
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;
      min-height: 478px;


      .career-container {
        display: flex;
        justify-content: center;
        padding-top: 26px;
        padding-bottom: 8px;
        text-align: left;
        justify-content: space-between;

        div:first-of-type {
          position: relative;
          width: 50%;
          margin-right: 32px;
          box-sizing: content-box;

        }
        div:last-of-type {
          width: 50%;
          padding-right: 32px;

        }
        .placeholder{
          width: calc(100% - 32px);
          margin-bottom: 28px;
          line-height: 24px;
        }
      }

        p {
          margin: 0;
          margin-bottom: 28px;
          font-size: 14px;
          line-height: 24px;

          span {
            font-weight: bold;
            color: $blue;
          }
        }

        p.single-field {
          width: 100%;
          padding: 0;
          overflow: hidden;
          word-break: break-word;
          text-align: justify;
        }
    }

    & .projects-wrapper {
      min-width: 361px;
      width: 35%;
      position: relative;
      padding-bottom: 0;
      padding-right: 5px;
      padding-left: 0;



      .projects {
        position: relative;
        // background: url("../../../assets/images/in_progress.svg") no-repeat center 30%;
        overflow-y: scroll;
        height: 100%;
        margin-top: 30px;
        padding-right: 32px;
        text-align: justify;
        .in-progress{
          position: absolute;
          font-size: 14px;
          line-height: 24px;
          width: 100%;
          color: $grey;
          top: 60%;
          text-align: center;
        }
      }


    }
  }

  .projects-title {
    margin-left: 32px !important;
  }

  .career-achivements {
    margin-top: 24px;
  }

  .shadow::after {
    content: '';
    position: absolute;
    display: block;
    height: 70px;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 0px 0px 13px 13px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, #FFFFFF 100%);;
  }
