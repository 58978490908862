@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.container {
  @include container;
  max-width: 1366px;
}

.header {
  padding: 16px 0;
  box-shadow: 0px 4px 6px 0px #3333330F;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
}

.headerActions {
  display: flex;
  align-items: center;
}

.loginHeader {
  margin-top: 0;
}

.signIn {
  margin-left: 40px;
}

.link {
  display: block;
  width: 170px;
  padding: 8px 0;
  box-shadow: 0px 5px 15px $box-shadow-btn;
  border-radius: 14px;
  background: $btn-login-bg;
  color: $white;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding: 7px 15px;
  text-decoration: none;
}


