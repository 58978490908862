@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.container {
  @include container;
  display: flex;
  padding-top: 20px;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
}

.backButton {
  margin-top: 20px;
  position: absolute;
  left: 50px;
  display: flex;
  color: #3737ED;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  gap: 6px;
  cursor: pointer;
}

.invoiceTable {
  margin-top: 64px;
  text-align: left;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;
}


.invoiceTableHeaderTr {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid #EBEBEB;
}

.invoiceTableTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #3737ED;
}

.invoiceTableBody {

}

.invoiceTableBodyTr {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  transition: all ease 0.5s;
  &:hover {
    background: #EFEFFF;
  }
}

.invoiceTableBodyId {

}

.invoiceTablePrice {
  color: #149B58;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}
