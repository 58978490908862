@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.panel {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;

  .methods {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;

    div {
      width: 360px;
      height: 180px;
      margin: 0px 20px;
      text-align: center;
      border-radius: 10px;
      border: 1px solid #EBEBEB;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      // box-sizing: border-box;
    }

    .shadow {
      border: 1px solid #3737ED !important;
      // box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 20px rgba(51, 51, 51, 0.5);
    }
  }

  .form {
    &:not(:last-child) {
      border-bottom: 1px solid $color-gray;
    }
  }

  .control {
    @include profile-info-section;
    width: 100%;
    min-width: 832px;
    text-align: left;

    .control-section-wrapper{
      margin: 50px 0;
      display: flex;
      justify-content: space-between;
    }

    .control-section {
      margin-top: 10px;
      min-width: 220px;

      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #3737ED;
        margin-bottom: 12px;
      }
    }

    .profit-head,
    .control-head {
      display: flex;
      justify-content: space-between;
    }

    .control-head {
      button {
        margin-left: 20px;
      }
    }

    .button-container {
      display: flex;
      justify-content: end;

      button {
        margin-left: 20px;
      }
    }
  }
}

.radio {
  min-height: 150px;
}

.additionalSettingsWrapperField {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.checkboxContainer,
.checkboxWrapper,
.additionalSettingsBtn,
.radioWrapper,
.additionalInputsWrapper {
  display: flex;
  align-items: center;
}

.additionalInputsWrapper {
  flex-direction: column;
  align-items: flex-start;
  max-width: 450px;
}

.checkboxContainer {
  gap: 80px;
  margin-bottom: 30px;
}

.label {
  margin-right: 10px;
  font-size: 14px;
}

.additionalSettingsBtn {
  @include profile-info-section;
  flex-direction: row;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 20px;
  margin: 0 auto 30px;

  &:hover {
    border: 1px solid $light-grey;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }

  & svg {
    height: 20px;
    width: 20px;
    margin-left: 20px;
  }
}

.general {
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
    height: 65px;

    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
    }

    p {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #3737ED;
    }
  }

  :last-child {
    border-bottom: none;
  }
}

.chart {
  overflow-x: scroll;
  height: 74.4vh;

  .table-head {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 0.7fr 1fr 1fr 1fr;

    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #3737ED;
    }
  }

  .table-item {
    display: grid;
    grid-template-columns: 0.7fr 1fr 1fr 1fr;
    height: 71px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .plus {
      color: #149B58;
    }

    .minus {
      color: #E12F75;
    }
  }

  .profit-date {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .period {
      display: flex;

      p {
        margin: 0px;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: #333333;
        margin-right: 16px;
        margin-bottom: 16px;
      }

      :last-child {
        color: #149B58;
      }
    }
  }
}

.positions {
  overflow-x: scroll;
  height: 74.4vh;
  padding-top: 56px;

  .table-head {
    margin-top: -56px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    position: fixed;
    width: 58.6%;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 14px;

    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #3737ED;
    }
  }

  .table-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 71px;
    align-items: center;

    :first-child {
      display: flex;
      // justify-content: center;
      align-items: center;
    }

    :last-child {
      p {
        margin: 0px;
        margin-bottom: -7px;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }

    .marker {
      width: 4px;
      height: 26px;
      margin-right: 8px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .plus {
      color: #149B58;
    }

    .minus {
      color: #E12F75;
    }

    .plus-background {
      background-color: #149B58;
    }

    .minus-background {
      background-color: #E12F75;
    }
  }
}


.wrapper {
  label {
    font-size: 12px;
    line-height: 18px;
    color: #9A9A9A;
    margin: 0;
    width: 100%;
    text-align: left;
    display: block;
  }
}

.container {
  border: 1px solid $blue;
  border-radius: 10px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 12.5vw;
  margin-bottom: 24px;

  .data-picker-dash {
    width: 6px;
    border-top: 1px solid $dark-grey;
  }

  .datepicker-icon {
    width: 12px;
    height: 12px;
    padding-left: 9px;
    padding-right: 9px;
    background: url(../../../../../assets/images/calendar.svg) no-repeat;
  }

  .data-picker-text {
    margin-right: 7px;
    padding-top: 6px;
    font-size: 12px;
    line-height: 16px;
    color: $dark-grey;
  }

  .data-picker-text-to {
    margin-left: 8px;
  }

  div[class^="react-datepicker__input-container"] input {
    border: 1px solid transparent;
    padding-left: 4px;
    padding-right: 4px;
    margin: 0;
    background: unset;
    box-shadow: unset;
    text-align: center;
  }
}

.toggleWrapper {
  margin: 25px auto 43px;
  display: flex;
  flex-direction: row-reverse;
  max-width: max-content;
}

.bidWrapper {
  display: flex;
}

.inputWrapper {
  display: flex;
  margin-top: 10px;
  gap: 40px
}

.buttonWrapper {
  display: flex;
  margin-top: 30px;
  gap: 20px;
}

.form-value {
  min-width: 350px;
}

.channelsSettingsWrapper {
  margin: 40px 0;
}

.actionBtnsWrapper {
  display: flex;
  justify-content: end;
  gap: 20px;
}

.select{
  display: block;
  height: 30px;
  width: 160px;
  margin: 20px auto 0;
  padding-left: 10px;
  border: 1px solid $color-blue;
  border-radius: 10px;
  text-align: center;

  &:disabled {
    border-color: $color-gray;
  }
}