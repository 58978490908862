@import "colors";
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-header: 28px;
$color-dark-blue: #110F30;
$color-green: #149B58;
$color-crimson: #E12F75;
$color-light-violet: #B3B3F8;
$color-light-grey: #EBEBEB;
$color-gray: #9A9A9A;
$color-blue: #3737ED;

$global-max-width: 1360px;

// Side bar
$color-white: #FFFFFF;
$color-black: #333333;
$size-lg: 30px;
$size-md: 20px;
$size-sm: 10px;
$width: 190px;
$width-collapsed: 48px;
$width-lg: 100%;
$bg-white: #FCFBFE;

$btn-size-md: 40px;
$btn-size-sm: 30px;
$btn-size-xs: 16px;

// ToggleBar
$toggle-bar-label-side-paddings: 10px;

// table
$border-table: 1px solid $light-grey;
$box-shadow-table: 0 0 20px $box-shadow;
$border-radius-table: 14px;

// header
$padding-header: 0 48px 0 32px;
$line-height-header: 16px;
$top-margin: 65px;
$header-width: calc(100% - #{$width});
$header-width-sidebar-collapsed: calc(100% - #{$width-collapsed});
$header-icons-height: 40px;
$header-icons-width: 40px;
$header-icons-margin: 0 5px;

// header th
$padding-header-th: 15px 0;
$min-width-th: 150px;

// header tr
$border-bottom-tr: 1px solid $light-grey;

// body
$min-height-body: 300px;
$max-height-body: 100vh;
$padding-bottom-body: 20px;

// body tr
$padding-body-tr: 0 43px;

//body td
$padding-body-td: 10px 0;
$width-td: 150px;

// table wrapper
$width-table-wrapper: 100%;
$margin-table-wrapper: 20px 0;
$shadow-webkit-scrollbar: inset 0 0 3px rgba(179, 179, 248, 0.2);
$width-webkit-scrollbar: 6px;
$border-radius-webkit-scrollbar: 5px;
$height-webkit-scrollbar: 64px;

//font family
$font-family:'Open Sans', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif