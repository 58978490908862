@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  min-width: 360px;
  width: 100%;

  .mini-title{
    @include mini-title;
  }

  .wrapper, .bonuses-container {
    color: $dark-grey;
    background-color: $white;
    border: 1px solid $light-grey;
    box-sizing: border-box;
    box-shadow: 0 0 20px $box-shadow;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    padding: 28px 32px;

    h2 {
      margin: 0;
      font-size: 20px;
      line-height: 28px;
    }

    .content-wrapper {
      margin-top: 32px;
      color: $dark-grey;
      background-color: $white;
      box-sizing: border-box;
      border-radius: 14px;
      display: flex;
      justify-content: space-between;
    }
    .title div:first-child,
    .balance div:first-child {
      padding-bottom: 13px;
    }
  }

  .row_withAnotherPadding {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 33.3%;
    &:nth-child(2){
      border-right: 1px solid $color-light-grey;
      border-left: 1px solid $color-light-grey;
    }
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.3%;
  }
  .diagram {
    min-width: 90px;
    height: 90px;
  }
  .info {
    font-weight: normal;
    min-width: 200px;
    display: flex;
    justify-content: space-between;
  }
  .title {
    width: 125px;
    margin-left: 20px;
    color: $dark-grey;
  }

  .balance {
    min-width: 85px;
    color: $green;
    font-weight: 600;
  }
  .balance-red {
    color: $require-error-color;
  }
  .credit-balance {
    color: $invalid-red;
  }

  .bonuses-container {
    margin-top: 24px;
  }
}
