@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.info-section,
.filesSection {
  @include profile-info-section;
  cursor: pointer;
  padding: 21px 24px 14px;
  min-height: 163px;

  .info-container {
    display: flex;
    justify-content: center;
    padding-top: 21px;
    text-align: left;
    justify-content: space-between;
    flex-direction: column;

    p {
      font-size: 14px;
      line-height: 24px;
      margin: 0px 0px 8px;
      max-width: 73%;
    }

    .meta-info {
      display: flex;
      justify-content: space-between;
      text-wrap: nowrap;
          display: flex;
          p {
              margin: 0px 50px 0px 0px;
              i {
                position: relative;
                top: 3px;
                margin-right: 8px;
              }
          }
      .about-price {
        display: flex;
        text-align: right;
        
        .price {
            opacity: 1 !important;
            color: #149B58;
        }
        .job_time {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin: 3px 0px 0px 0px;
        }
        .orange {
          color: $orange !important;
        }
        .custom {
          font-size: 10px;
          line-height: 14px;
          opacity: 0.6;
          color: $grey;
        }
      }
  }
  }
}

.price {
  width: max-content;
  margin-left: 50px;
  opacity: 1 !important;
  color: #149B58;
}

.onetask {
  text-decoration: none;
  color: $dark-grey;
}

.canceled,
.work,
.interwiev,
.ready,
.created {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 90px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.created {
  color: $points_icon;
  background-color: $pink-light;
}

.ready {
  color: $blue;
  background-color: $light-blue;
}

.interwiev {
  color: $orange;
  background-color: $orange-light;
  width: 85px;
}

.work {
  color: $green;
  background-color: $light-green;
}

.canceled {
  width: max-content;
  color: $red;
  background-color: $light-red;
}

.btn {
  width: 210px;
  height: 32px;
  font-size: 14px;
  line-height: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;

  &-offer {
    width: 130px;
  }

  &-request {
    width: 180px;
    height: 32px;
  }
}

.wrapper {
  display: flex;
  max-width: max-content;
  align-items: center;
}