@import '../../assets/style/colors';

.wrapper {
  label {
    font-size: 12px;
    line-height: 18px;
    color: #9A9A9A;
    margin: 0;
    width: 100%;
    text-align: left;
    display: block;
  }
}

.container {
  border: 1px solid $blue;
  border-radius: 10px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .data-picker-dash {
    width: 6px;
    border-top: 1px solid $dark-grey;
  }

  .datepicker-icon {
    width: 12px;
    height: 12px;
    padding-left: 9px;
    padding-right: 9px;
    background: url(../../assets/images/calendar.svg) no-repeat;
  }

  .data-picker-text {
    margin-right: 7px;
    padding-top: 6px;
    font-size: 12px;
    line-height: 16px;
    color: $dark-grey;
  }

  .data-picker-text-to{
    margin-left: 8px;
  }

  div[class^="react-datepicker__input-container"] input{
    border: 1px solid transparent;
    padding-left: 4px;
    padding-right: 4px;
    margin: 0;
    background: unset;
    box-shadow: unset;
    text-align: center;
  }
}