@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.container {
  padding: 26px 20px;
  margin: 0 auto;
  width: 100%;
}

.navWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.back-btn {
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: $blue;

  svg {
    margin-right: 6px;
  }
}

.back-btn,
.back-btn svg {
  display: flex;
  align-items: center;
}

.spinnerWrapper {
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.commentsWrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 49%));
  gap: 2%;
}

.header {
  @include top-header;

  h1 {
    @include top-header-h1
  }
}
