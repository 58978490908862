@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  min-width: 360px;
  width: 100%;

  .mini-title {
    @include mini-title;
  }

  .wrapper {
    box-sizing: border-box;
    box-shadow: 0 0 20px $box-shadow;
    border-radius: 14px;
    border: 1px solid #EBEBEB;
    background: #FFFFFF;
    padding: 28px 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    .status {
      display: flex;
      flex-direction: column;

      .textStatus {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .message {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #333333;
        width: 295px;
      }
      .imageStatus {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
      }
    }
  }
}
