@import '../../../../../../assets/style/colors';
@import '../../../../../../assets/style/variables';

$table-item-width: 180px;

.table-header-wrapper {
  display: flex;
  padding: 8px 24px 24px;
  position: relative;
  justify-content: space-between;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $light-grey;
  }
}

.table-body-inner {
  max-height: 500px;
  height: 100%;
  overflow: auto;
  position: relative;
}

.table-header {
  font-weight: 700;
  font-size: 1rem;
  line-height: 18px;
  color: $blue;
  width: $table-item-width;

  &:last-child {
    margin-right: 0;
  }
}

.table-body-wrapper {
  display: flex;
  padding: 0 24px;
  text-align: left;
  padding-bottom: 15px;
  padding-top: 15px;
  align-items: center;
  position: relative;
  justify-content: space-between;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $light-grey;
  }

  &:first-child {
    padding-top: 15px;
  }
}

.table-body {
  
  width: $table-item-width;
  text-align: center;


  &:last-child {
    margin-right: 0;
  }
}

.table-no-data {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
