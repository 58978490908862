@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';

.modal-show {
    display: block;
  }

  .modal-hide {
    display: none;
  }

.rbc-header {
    text-align: center;
    border-bottom: 1px solid #9A9A9A
}

.rbc-time-slot {
    position: relative;
}

.rbc-label {
    font-size: 10px;
    position: absolute;
    top: -6px;
    right: -73px;
    justify-self: flex-end;
    color: #9A9A9A;

}

.rbc-time-content {
    border-top: none;
    padding-top: 10px;
}

.rbc-time-header-content > .rbc-time-header-cell {
    margin-left: 73px;
}

.rbc-time-content > .rbc-day-slot > .rbc-timeslot-group  {
    border-left: 0px !important;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.rbc-time-content > .rbc-time-gutter {
    padding-right: 75px;
    display: flex;
    justify-content: flex-end;
}

.rbc-header + .rbc-header {
    border: none;
}

.rbc-time-header {
    flex-direction: unset;
}

.rbc-time-view {
    border: none
}

.rbc-time-header-content {
    border-left: none;
    padding-top: 5px;
}

.rbc-header {
    border-bottom: none;
    button {
        span {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #3737ED;
        }
    }
}

.rbc-time-view .rbc-row {
    min-height: 0px !important;
}

.rbc-time-view .rbc-allday-cell {
    height: 0%;
}

.rbc-current-time-indicator {
  background-color: rgb(184, 12, 84);

  &::before {
    background-color: rgb(184, 12, 84);
    left: -5.5px;
  }

  &::after {
    display: block;
    position: absolute;
    right: -4px;
    top: -3px;
    content: ' ';
    background-color: rgb(184, 12, 84);;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
}

.rbc-event, .rbc-event.rbc-selected {
  background-color: #3737ED;
}

.rbc-day-slot .rbc-time-slot {
  border: none;
}

.rbc-calendar {
  max-height: 700px;
}

.rbc-event, .rbc-background-event {
  box-shadow: 1px 1px 1px 3px #ffffff;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  border-radius: 6px;
}

.overlap {
  left: 40% !important;
  width: 65% !important;
}

.month {
  font-size: 16px;
  color: #333;
  margin-right: 15px;
}

.year {
  color: #9A9A9A;
  font-size: 16px;
}

.custom-arrow {
  cursor: pointer;
}
