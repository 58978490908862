@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';


.container {
  @include container;
  display: flex;
  padding-top: 20px;

  .header {
    @include top-header;

    h1 {
      @include top-header-h1
    }
  }

  .main {
    width: 100%;
    display: flex;
    justify-content: center;

    .form {
      min-width: 928px;
      background: $color-white;
      border: 1px solid $light-grey;
      box-sizing: border-box;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
      border-radius: 14px;
      text-align: left;
      padding: 28px 32px 40px;

      h2 {
        font-size: 30px;
        line-height: 41px;
        margin: 0;
        color: $dark-grey;
      }

      .row {
        padding-top: 24px;
        display: flex;

        .label {
          font-size: $font-size-xs;
          color: $grey;
          margin-bottom: 4px;
        }

        .message {
          flex-grow: 1;

          textarea {
            margin: 0;
          }
        }

        .budget {
          margin-right: 24px;

          input {
            font-size: 12px;
          }
        }

        .term input {
          margin: 0;
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;

      .bidsButton {
        display: flex;
        align-items: center;

        .bidsAmount {
          margin-right: 20px;
          font-weight: bold;
          color: $dark-grey;

          span:nth-child(2) {
            color: $green;
          }
        }
      }

      .actionsButton {
        display: flex;

        button:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}

.checkboxes {
  display: flex;
  margin-top: 24px;
}

.LoadingModal {
  min-height: 0;
  padding: 10px 32px 24px;

  p {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }

  .success .btn {
    width: fit-content;
    margin: 0 auto;

    button {
      width: 172px !important;
      font-size: $font-size-sm;
    }
  }
}