@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  @include container;
  position: relative;
}

.header {
  @include top-header;

  h1 {
    @include top-header-h1
  }
}

.toggle {
  // width: 320px;
  margin-bottom: 15px;
}