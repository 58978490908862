@import "../../assets/style/variables";

.container {
  width: 100%;
  display: flex;
  flex: 1 1;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.title {
  min-width: 200px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: $color-dark-blue;
  padding: 20px 0;
}
