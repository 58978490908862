@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.container {
  @include container;

  .header {
    @include top-header;

    h1 {
      @include top-header-h1;
      cursor: pointer
    }
  }

  position: relative;
  margin: 15px 0px 25px;
  color: $dark-grey;

  .personal-info {
    @include profile-info-section;

    text-align: left;

    p {
      font-size: 14px !important;
    }

    h2 {
      margin-bottom: 24px;
    }

    .tax {
      span {
        color: $green;
        font-weight: 700;
      }
    }
  }

  .uploadLabel {
    position: relative;
    margin: 20px 0px 30px;
    cursor: pointer;
    height: 40px;
    text-align: center;

    .upload-cross {
      position: absolute;
      right: 49%;
      left: 49%;
      top: 13px;
    }
  }

  .file-upload {
    position: absolute;
  }

  .add-files-block {
    margin-top: 24px;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .add-file-arria {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      border: dashed 1px $blue;
      border-radius: 8px;
      margin-top: 13px;
      cursor: pointer;

      label {
        cursor: pointer;
      }
    }

    .add-image-arria {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 275px;
      width: 455px;
      background: #EBEBEB;
      border-radius: 8px;
      margin-top: -24px;
      cursor: pointer;

      label {
        cursor: pointer;
      }
    }
  }

  .budget {
    display: flex;
    justify-content: space-between;

    p {
      margin-left: 265px
    }

    .check {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;

      p {
        left: 0px;
        margin: 0px;
        right: 20px;
        padding: 0px;
      }
    }
  }

  .rlevel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .technologies,
  .description-task {
    width: 100%;
    min-height: 98px;
    margin-bottom: 32px;

    textarea {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 18px;
      padding: 6px 12px;
    }
  }

  .deadline {
    margin-bottom: 24px;
    width: 205px;
  }

  .budget-task {
    margin-bottom: 24px;
    width: 100%;
    margin-right: 16px;
  }

  .budget-task-hour {
    margin-bottom: 24px;
    width: 100%;
  }

  .currency,
  .specials,
  .level,
  .head-task {
    margin-bottom: 24px;
    width: 100%;
  }

  .specials div[class$="-control"]{
    height: unset;
  }

  .checkboxes {
    display: flex;
    margin-bottom: 24px;
  }

  .accept-btn {
    height: 40px;
    width: 210px;
    margin-left: 20px;

    button {
      cursor: pointer;
      padding: 0px;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }
  }

  .cancel-btn {
    height: 40px;
    width: 210px;
    background: $white;
    border-radius: 14px;
    box-shadow: 0px 5px 15px rgba(55, 55, 237, 0.1);
    border: 1px solid $blue;
    color: $blue;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    padding: 0 15px;
    transition: all 0.2s;

    &:hover {
      background: $blue-hover;
      color: $white;
      border-color: $blue-hover;
      cursor: pointer;
    }
  }

  .draft-save {
    color: $blue;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.2s;

    &:hover {
      color: $blue-hover;
    }
  }

  .back-btn {
    font-size: 14px;
    cursor: pointer;
    color: $blue;

    svg {
      position: relative;
      right: 5px;
      top: 3px;
    }
  }
}

.back-arrow {
  position: relative;
  bottom: -7px;
}

.create-btn {
  position: absolute;
  right: 0px;
  margin-right: 61px;
  margin-top: 44px;

  button {
    cursor: pointer;
  }
}

.type-description {
  margin: 0px 39px;
  max-width: 1280px;
}

.block-about {
  color: $blue;
  margin: 30px 0px 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.about-type {
  h2 {
    margin: 0px 0px 10px;
    font-size: 30px;
    line-height: 40px;
  }

  p {
    margin: 10px 0px 0px;
    font-size: 14px;
    line-height: 24px;
  }
}

.advantages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  div {
    width: 110px;
    height: 78px;
  }
}

.how {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  div {
    width: 110px;
  }

  .step {
    margin: 0px 0px 34px;
    color: $color-dark-blue;
    position: relative;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }

  .step::before {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: 30.5px;
    height: 10px;
    width: 10px;
    border-radius: 2.5px;
    background-color: #FF5F1B;
    rotate: 45deg;
  }
}

.how::before {
  content: "";
  position: absolute;
  left: 0;
  top: 35px;
  height: 1.5px;
  width: 100%;
  background-color: $blue;
}

.modal-loader {
  position: absolute;
  display: flex;
  z-index: 20;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  padding-top: 30vh;
  width: 100%;
  height: 100%;
  margin-right: 20px;
  border-radius: 20px 20px 10px 10px;

  div {
    position: sticky;
    top: 170px;
  }
}

.main-info-project {
  display: flex;
  width: 100%;

  .info-field {
    text-align: left;
    width: 100%;
    margin-left: 32px;

    textarea {
      height: 163px;
    }
  }
}

.basic-info {
  display: flex;

  .dates {
    display: flex;

    .deadline {
      margin-bottom: 24px;
      width: 196px;
      margin-right: 32px;
    }
  }

  .stage {
    margin: 32px;
    margin-left: 0px;
    width: 100%;
  }

  .dates-area {
    width: 100%;
  }
}

.column {
  display: flex;
  width: 80%;

  div {
    width: 100%;
    margin-right: 32px;
  }
}

.add-files-block {
  p {
    left: 0px;
    position: unset;
    padding: 0px;
    margin-top: 0px;
    color: #9A9A9A;
    font-size: 12px;
    line-height: 18px;
  }
}

.add_vacancy {
  margin: 16px auto 4px;
  cursor: pointer;
}

.delete_vacancy {
  margin-left: auto;
  cursor: pointer;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
}
