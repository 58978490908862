@import '../../assets/style/colors';
@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.personal-info {
  @include profile-info-section;
  text-align: left;
}

.taskTitle {
  margin-top: 0;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0.01em;
}

.avatar {
  height: 40px;
  width: 40px;
  margin-right: 14px;
  border-radius: 50%;
}

.info-task {
  padding-right: 10%;
  padding-top: 24px;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  border-top: 1px solid $light-grey;
  align-items: center;
}

.infoDescription {
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
  color: $grey;
  font-weight: 700;
}

.userInfoDescription {
  display: flex;
  align-items: center;
}

.userName {
  margin-bottom: 6px;
}

.userRating {
  font-weight: 600;
  font-size: 12px;
}

.userName,
.userRating {
  margin: 0;
}

.negativeRating {
  color: $red;
}

.positiveRating,
.taskPrice {
  color: $green;
}

.taskPrice,
.deadline,
.userName {
  font-weight: 700;
  font-size: 20px;
}