@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.card-container {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
    cursor: pointer;

.text {
        top: 0px;
        bottom: 0px;
        color: $dark-grey;
        position: absolute;
        z-index: 15;
        left: 0; 
        right: 0;
        margin: auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
    }
    img {
        width: 358px;
        height: 121px;
        border-radius: 10px;
    }

    .opacity-active {
        background-color: $blue;
        opacity: 0.2;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 12;
        border-radius: 10px;
    }
}

.card-container:after {
    width:100%;
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    background: linear-gradient(to right, rgb(255, 255, 255) 0%,rgba(255,255,255,0) 69%);
    height:121px;
    border-radius: 10px;
}

.active {
    color: aliceblue;
}

.choosen {
    position: absolute;
    top: 0;
    left: 0;
    width: 3%;
    height: 100%;
    background-color: $blue;
    z-index: 11;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}