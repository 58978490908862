@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import '../../../assets/style/mixins';

.container {
  @include container;
  position: relative;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .content-container {
    display: flex;
    justify-content: space-between;
  }

  form {
    text-align: left;
    &>div>div>div {
      margin-bottom: 24px !important;
    }
  }

}

.block-go-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  font-size: 12px;
  margin-top: 26px;
  padding-bottom: 26px;
}

.go-back {
  background: none;
  color: $blue;
  padding: 0;
  cursor: pointer;
  svg {
    margin-bottom: -2.5px;
  }
  svg path {
    stroke: $blue;
  }
  .go-back-text {
    margin-left: 8px;
    font-size: 14px;
  }
}

.label-for-field {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  color: $color-gray;
}

.red-note {
  padding-left: 5px;
  display: inline;
  color: $invalid-red;
  font-size: 12px;
}

.primary-form {
  display: grid;
  grid-template-columns: 27% 71%;
  grid-template-rows: 53% 35% 5%;
  grid-column-gap: 27px;
  grid-row-gap: 32px;
}

.table {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;
  overflow: auto;
  grid-area: 1 / 2 / 2 / 3;

  &>div {
    width: 130%;
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0px;
  }
}

.comment {
  grid-area: 2 / 1 / 3 / 3;
}

.summary-wrapper {
  grid-area: 3 / 1 / 4 / 3;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: $dark-grey;
  margin: 0;
  margin-bottom: 24px;
  text-align: left;
}

.main-info {
  min-width: 26%;
  padding: 28px 32px 10px 32px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;
  grid-area: 1 / 1 / 2 / 2;
}

.two-columns {
  text-align: left;
}

.two-columns-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline
}

.two-columns-inner {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}


.two-columns-inner:last-child {
  &>div:first-child {
    width: 100%;
    margin-right: 35px;
  }

  &>div:last-of-type {
    width: 100%;
  }

  &>div>div>div>div {
    margin-bottom: 0 !important;
  }
}

.divider {
  background-color: $light-grey;
  width: 100%;
  height: 1px;
  margin-bottom: 24px;
  margin-top: 8px;
}

.input-invoice {
  width: 100%;
}

.table-wrapper{
  overflow: hidden;
  min-width: 72%;

  &>div:first-child {
    overflow: auto;
  }

  form > div {
    overflow: auto;
  }

  form > div > div > div:first-child {
    width: 119%;
  }

  form > div > div:nth-child(2) {
    &>div>div {
      width: 1437px !important;

      &>div>div>div:last-child {
        &>div:first-child {
          width: 200px;
          margin-right: 5px;
        }
        &>div:last-child {
          width: 200px;
          margin-left: 5px;
        }
      }
    }
  }
}

.comment-wrapper {
  margin-bottom: 31px;
  padding: 28px 32px 24px 32px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;
}

.summary-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.summary {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;

  span {
    color:$green
  }
}

.button {
  width: 160px !important;
  cursor: pointer;

  &:first-child {
    margin-right: 20px;
  }
}

.modal {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    position: absolute;
    right: 15px;
    top: 25px;
  }
}

.spinner {
  width: 100% !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}





