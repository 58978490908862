@import '../../../../../assets/style/colors';

.container{
    padding: 20px;
    width: 400px;
    height: 500px;
    background: $white;
    border: 1px solid $light-grey;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 14px;

    .header{
        display: flex;
      column-gap: 20px;
        align-items: center;

        &-avatar{
            width: 60px;
            height: 60px;
        }

        &-author{
            font-size: 17px;
            font-weight: 700;
        }
    }

    .body{
        margin-top: 50px;
        height: calc(100% - 170px);

        .details-title{
            font-size: 18px;
            font-weight: bold;
        }

        .details-text{
            margin-top: 10px;
            padding: 10px 0;
            height: calc(100% - 40px);
            text-align: justify;
            overflow: auto;
        }
    }

    .footer{
        display: flex;
        justify-content: center;
        align-items: end;
        column-gap: 20px;
        height: 60px;
        border-top: 1px solid $light-grey;
        
    }
}

.empty-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    align-items: center;
    height: 100%;
  
    .text{
      width: 200px;
      font-weight: bold;
      font-size: 19px;
    }
  }