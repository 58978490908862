@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.wrapper {
  display: flex;
  @include main-text;
  width: 100%;
  border-bottom: 1px solid $scroll-bar-thumb;
  padding: 5px 0;
  position: relative;

  .label {
    position: absolute;
    top: 8px;
    left: 0;
    color: $dark-grey;
    margin-bottom: 7px;
  }

  input {
    border: none;
    background: #FFFFFF;
    width: inherit;
    -webkit-appearance: none;

    &:read-only {
      color: $color-gray;
    }

    &:focus {
      outline: none;

      +.label {
        display: none
      }
    }
  }
}

.wrapper-required {
  .label::after {
    content: '*';
    color: $require-error-color;
    position: absolute;
    top: -5px;
    right: -8px;
  }
}

.wrapper-disabled {
  border-bottom: 1px solid $grey;

  .label {
    color: $grey;
  }
}

.wrapper-error {
  border-bottom: 1px solid $require-error-color;

  .label {
    color: $require-error-color;
  }
}

.wrapper-outlined {
  height: 30px;
  border-radius: 10px;
  border: 1px solid $blue;
  color: $dark-grey;
  position: relative;
  @include label-text;

  .label {
    position: absolute;
    top: 6px;
    left: 10px;
    color: $grey;
  }

  input {
    margin: 0;
    border: none;
    padding: 0 10px;
    background: transparent;
    width: inherit;

    &:focus {
      outline: none;

      +.label {
        display: none
      }
    }
  }
}

.wrapper-outlined-required {
  .label::after {
    content: '';
  }
}

.wrapper-outlined-disabled {
  border: 1px solid $grey;

  .label {
    color: $grey;
  }
}

.wrapper-outlined-error {
  border: 1px solid $require-error-color;

  .label {
    color: $require-error-color;
  }
}


.label-outlined {
  @include label-text;
  position: relative;
  color: $grey;
  margin-bottom: 7px;
}

.label-outlined-required {
  position: relative;
}

.label-outlined-required::after {
  content: "*";
  top: -5px;
  right: -5px;
  position: absolute;
  color: $require-error-color;
}

.label-outlined-error {
  color: $require-error-color;
}

.tooltip {
  background-color: $require-error-color !important;
  border-radius: 6px;
  padding: 3px 12px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: $white;
  border: 1px solid $light-grey;
  box-shadow: 0 0 20px $box-shadow;
  margin-top: 15px !important;

  &::after {
    border-bottom-color: $require-error-color !important;
  }
}

.tooltipInfo {
  border-radius: 6px;
  padding: 3px 12px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  border: 1px solid $light-grey;
  box-shadow: 0 0 20px $box-shadow;
  margin-top: 15px !important;
  background-color: $white !important;
  color: $dark-grey !important;
}
