@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.container {
  @include container;
  position: relative;
  height: calc(100vh - 130px);
}

.toggle {
  text-align-last: left;
}

.spinner-wrapper {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-tasks {
  margin-top: 60px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.prev-button, .next-button {
  margin-top: 2px;
  svg {
    path {
      fill: $green;
    }
  }
  &:disabled {
    svg {
      path {
        fill: #AAAAAA;
      }
    }
  }
}


