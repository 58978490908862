@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.wrapper-cell-roles{
  display: flex;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  flex-wrap: wrap;
}
.role{
  width: 90px;
  border-radius: 20px;
  padding: 2px 5px;
  margin: 3px;
}
.admin{
  width: 47px;
  color: $color-crimson;
  background: $light-red;
}
.manager{
  color: $blue;
  background: $light-blue;
}
.user{
  color: $dark-green;
  background: $light-green;
}
