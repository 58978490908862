@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.addModal,
.modal {
  display: flex;
  flex-direction: column;
  width: 358px;
  min-height: 230px;
  padding: 24px;
  padding-bottom: 20px;
  text-align: initial;
  background-color: $white;
  border: 1px solid $light-grey;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;
}

.addModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal {
  justify-content: space-between;
  height: 546px;
  padding-right: 5px;
}

.fieldWrapper,
.fieldWrapperMargin {
  margin-bottom: 16px;
}

.fieldWrapperMargin {
  margin-right: 19px;
}

.tokensWrapper {
  margin-bottom: 20px;
  max-height: 357px;
  overflow-y: scroll;
}

.insideTokensWrapper {
  margin-right: 19px;
}

.titleMargin,
.title {
  margin: 0;
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.01em;
  color: $dark-grey;
  text-align: center;
}

.titleMargin {
  margin-right: 18px;
}

.subTitle {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  color: $grey;
}

.customTokens {
  margin-bottom: 12px;
  border-bottom: 1px solid $light-grey;
}

.tokenItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 14px;
}

.tokenImage {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.tokenItemTitle {
  font-size: 14px;
  line-height: 24px;
}

.tokenItemDescription {
  font-size: 10px;
  color: $grey;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-right: 19px;
}

.checkWrapper {
  display: flex;
  align-items: center;
  margin-bottom: -12px;
}

.input {
  margin-right: 9px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  color: $dark-grey;
}

.tokenItemWrapper {
  display: flex;
  align-items: center;
}

.deactivatedButton,
.activatedButton {
  cursor: pointer;
}

.activatedButton svg {
  height: 20px;
  width: 20px;
}

.is-activated {
  color: $grey;
}

.gray-img {
  opacity: 0.5;
  filter: grayscale(100%);
}

.closeAddModal {
  display: flex;
  justify-content: flex-end;
}

.totalTokens {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: $grey;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
}

.alertWrapper {
  margin-bottom: 20px;
}