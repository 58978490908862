@import "../../assets/style/variables";

.accordion_li {
  line-height: 25px;
  .accordion_a {
    position: relative;
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-content: center;
    color: $color-white;
    cursor: pointer;
    text-decoration: none;

    .accordion_icon  {
      width: 24px;
      position: relative;
      svg {
        position: absolute;
        display: none;
        top: 40%;
      }
    }
  }

  .accordion_a_active::after {
    content: '';
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .accordion_a[aria-current="page"] {
    text-decoration: none;
    span{
      color: $color-white;
    }
    .accordion_icon svg{
      display: block;
    }
    .accordion_icon svg path {
      fill: $color-white;
    }
    transition: all 0.23ms ease-in-out;
  }
}
