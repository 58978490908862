@import '../../assets/style/colors';
@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  z-index: 999;
  transition: all 500ms;
  cursor: pointer;
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  visibility: hidden;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }

  .modalTitle {
    margin-bottom: 12px;
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: 700;
    color: $dark-grey;
    text-align: center;
  }

  .modal {
    min-width: 420px;
    height: 254px;
    padding: 32px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #333;

    .close-cross {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }

    h2 {
      margin: 0px;
    }

    p {
      width: 300px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin: 16px 0px 24px;
    }

    .button-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
}


.hideModalToDownload {
  left: -9999px;
}

.is-activated {
  color: $grey;
}