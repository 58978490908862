@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.container {
  @include container;

  .header {
    @include top-header;

    h1 {
      @include top-header-h1
    }
  }

  margin: 15px 0px 25px;
  color: $dark-grey;

  .personal-info {
    @include profile-info-section;

    text-align: left;

    p {
      font-size: 14px !important;
    }

    .about-task {
      margin: 24px 0px 0px 0px !important;

      span {
        line-height: 24px;
        color: $blue;
        font-weight: bold;
      }
    }

  }
}

.btn {
  position: relative;
  height: 40px;
  width: max-content !important;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  word-break: keep-all;

  &-sign {
    width: 230px !important;
    height: 40px;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    padding: 0px;
    word-break: keep-all;

    svg {
      margin-top: 8px;
    }

    &:hover &__svg {
      path {
        fill: #fff;
      }
    }

    &-sign {
      width: 230px !important;
      height: 40px;
      cursor: pointer;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.wrap-bar {
  margin-bottom: 12px;
  display: flex;
  height: 40px;
  justify-content: space-between;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.task-avatar {
  height: 275px;
  width: 455px;
  border-radius: 8px;
  margin-right: 32px;
}

.title-name {
  margin: 0px 0px 28px 0px;
}

.info-task {
  line-height: 24px;
  font-size: 14px !important;
  padding-right: 5%;
  position: relative;
  display: flex;
  justify-content: space-between;
  text-align: left;
  border-top: 1px solid #EBEBEB;
  border-bottom: none;
  align-items: center;

  p {
    font-size: 14px;
    color: $grey;
    font-weight: 700;
  }
}

.border-bottom {
  border-bottom: 1px solid #EBEBEB !important;
  margin-bottom: 32px;
}

.user-info {
  left: 50px;
  top: 40px;
  position: absolute;

  p {
    margin: 0;
  }
}


.task-main {
  display: flex;
  margin-bottom: 42px;
  text-align: left;

  .about-task {
    margin: 24px 0px !important;

    span {
      line-height: 24px;
      font-size: 14px;
      font-weight: 700;
      color: $blue;
      font-weight: bold;
    }
  }
}

.filesSection {
  @include profile-info-section;
}

.actionsButton {
  display: flex;
}

.light-btn {
  height: 40px;
  width: 180px !important;
  background: $white;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(55, 55, 237, 0.1);
  border: 1px solid $blue;
  color: $blue;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  padding: 0 15px;
  transition: all 0.2s;
  margin-right: 20px;

  svg {
    margin-top: 8px;
  }

  &:hover {
    background: $blue-hover;
    color: $white;
    border-color: $blue-hover;
    cursor: pointer;

    .btn__svg {
      path {
        fill: #fff;
      }
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.back-btn {
  color: $blue;
  font-weight: 600;
  font-size: 14px;
}

.back-btn,
.back-btn svg {
  display: flex;
  align-items: center;
}

.back-btn svg {
  margin-right: 5px;
}

.toggle {
  text-align: left;
}

.replyBtn {
  margin-left: auto;
}

.ansersWrappper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
