@import '../../assets/style/colors';
@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  z-index: 999;
  transition: all 500ms;
  cursor: pointer;
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  visibility: hidden;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }

  .modalTitle {
    margin-bottom: 12px;
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: 700;
    color: $dark-grey;
    text-align: center;
  }

  .modal {
    padding: 32px 25px;

    .form {
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: flex-end;
      text-align: left;

      .amount {
        input {
          width: 200px;
        }
      }

      .price {
        font-weight: bold;
        color: $dark-grey;
        line-height: 30px;

        span:nth-child(2) {
          color: $green;
        }
      }

      .message {
        flex-grow: 1;
        margin-bottom: 24px;

        textarea {
          margin: 0;
        }
      }

      .label {
        font-size: $font-size-xs;
        color: $grey;
        margin-bottom: 4px;
      }

      .check {
        margin-bottom: 24px;
        display: flex;
      }

      &.cancel-task-form {
        flex-direction: column;
        align-items: initial;
      }
    }

    .closeModalButton {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .returnButton {
      position: absolute;
      top: 15px;
      left: 15px;
      cursor: pointer;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;
    }

    .buttons-suggest {
      margin-top: 78px;
      justify-content: center;

      button {
        margin: 0 10px;
      }
    }

    .buttons-cancel {
      justify-content: center;

      button {
        margin: 0 10px;
      }
    }

    &.modal-size-small {
      min-width: 360px;
      max-height: 540px;
      min-height: 240px;
    }

    &.modal-size-default {
      min-width: 460px;
    }

    &.modal-size-md {
      min-width: 616px;
    }
  }
}

.LoadingModal {
  min-height: 0;
  padding: 10px 32px 24px;

  p {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }

  .success .btn {
    width: fit-content;
    margin: 0 auto;

    button {
      width: 172px !important;
      font-size: $font-size-sm;
    }
  }
}

.hideModalToDownload {
  left: -9999px;
}

.popularTokenList {
  display: flex;
  gap: 6px;
  margin-bottom: 20px;
}

.popularTokenItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  font-size: 12px;
  background-color: #EFEFFF;
  border-radius: 20px;
}

.bg {
  border-color: #00774f;
  background-color: #00774f;
}

.popularTokenName,
.tokenItemTitle {
  font-weight: 600;
}

.tokenImage,
.popularTokenImage {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.tokenImage {
  margin-right: 8px;
}

.popularTokenImage {
  margin-right: 4px;
}

.tokenList {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 287px;
  margin-bottom: 20px;
  gap: 14px;
  overflow-y: scroll;
}

.tokenItem {
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $light-grey;
  }
}

.tokenSvg {
  margin-right: 14px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
}

.tokenItemTitle {
  font-size: 14px;
  line-height: 24px;
}

.tokenManageList {
  display: flex;
  flex-direction: column;
  max-height: 286px;
  gap: 14px;
  padding-right: 10px;
  margin-bottom: 20px;
  overflow-y: scroll;
}

.tokenItemDescription {
  font-size: 10px;
  color: $grey;
}

.tokenItemContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalTokens {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: $grey;
}

.deactivatedButton,
.activatedButton {
  cursor: pointer;
}

.activatedButton svg {
  height: 20px;
  width: 20px;
}

.is-activated {
  color: $grey;
}

.gray-img {
  opacity: 0.5;
  filter: grayscale(100%);
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  padding: 3px 0 2px;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 190px;
}

.alertWrapper {
  margin-bottom: 20px;
}

.withdrawalModal {
  &.modal {
    &.modal-size-default {
      min-width: 360px;
    }
  }

  form>div>div>div {
    margin-bottom: 0 !important;
  }

  .withdaw-select {
    position: absolute;
  }

  .interval-selected {
    margin-left: 12px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      color: $blue-hover;
    }
  }

  .field {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    margin-top: 55px;

    .output {
      margin-left: 12px;
      width: 120px;

      input {
        margin: 0;
        font-size: 14px;
      }

      *[class*="label"] {
        display: none;
      }
    }

    label {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: $color-black;
    }

    .intervalValue {
      margin-left: 12px;
      width: 70px;
      flex-shrink: 0;
    }
  }

  .label {
    flex-shrink: 0;
  }

  .saveBtn {
    display: block;
    cursor: pointer;
    margin: 148px auto 0 auto;
  }

  .close-btn {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    padding: 0;

    i,
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.subTitle {
  text-align: left;
  font-size: 16px;
}

.splitSumContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.splitSumWrapper {
  max-width: 150px;
  width: 100%;
}

.controllerDescription {
  max-width: 310px;
  font-size: 12px;
}