@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.container {
  @include container;
  margin: 15px 0px 25px;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .wrapBar {
    text-align: left;

    .wrapHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .wrapFilter {
    margin-top: 18px;
  }


}

.vacancies-table {
  & > div {
    min-width: unset !important;
    //overflow: visible;
  }
  position: relative;
  .formatCell {
    width: 100%;
    text-align: center!important;
  }
  * [class*="last_format_cell"] {
    padding-right: 60px;
  }
  .formatHeader {
    text-align: center;
  }
  * [class*="last_format_header"] {
    padding-right: 60px;
  }
}

.vacancies-filter-projects {
  position: relative;
  & > div {
    justify-content: start;
  }
}

.vacancies-add-project-btn {
  position: absolute;
  top: -69%;
  right: 0;
}

.ttest {
  position: relative;
}
