@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  z-index: 999;
  transition: all 500ms;
  cursor: pointer;
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  visibility: hidden;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }

  h2 {
    @include mini-title;
    text-align: center;
    margin: 0;
  }

  .modal {
    padding: 32px 25px;

    .buttons {
      margin-top: 22px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;
      column-gap: 24px;
      button {
        font-size: 14px;
      }
    }
  }
}