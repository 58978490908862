@import '../../assets/style/colors';

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  background: $light-grey-2;
  border-radius: 12px;
}

.notification svg {
  margin-right: 5px;
}

.alertDescription {
  font-size: 10px;
  color: $grey;
}