@import '../../../../../../../assets/style/colors';
@import '../../../../../../../assets/style/variables';
@import '../../../../../../../assets/style/mixins';

.container {
  @include container;

  margin-top: 20px;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

}

.back-btn {
  margin: 15px 0px 15px;
}

.verification {
  @include table-wrapper;

  margin-top: 10px;
  max-width: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  min-height: 70vh;
}

.passport {
    margin-top: 100px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-around;
    position: relative;
    .passport_up {
        position: absolute;
        opacity: 0.2;
        height: 275px;
        width: 455px;
    }
    .named {
        position: absolute;
        top: -60px;
    }
}

.add-image-arria {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    width: 455px;
    border: dashed 1px $blue;
    background: #EBEBEB;
    border-radius: 8px;
    cursor: pointer;
    label {
      cursor: pointer;
      p {
        left: 0px;
      }
      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
    }
    .delete {
        position: absolute;
        bottom: -25px;
    }
}

.claimsqr {
  @include table-wrapper;

  max-width: none;
  margin-top: 15px;
  align-items: center;
  display: flex;
  min-height: 90vh;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 50px;
}

.spinner {
  display: flex;
  justify-content: center;
  padding-top: 200px;
}

.step {
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
  div {
      display: flex;
      justify-content: space-around;
      margin: 10px 0px 10px 0px;
  }
  a {
      font-weight: 600;
      color: $color-blue;
      text-decoration: none;
  }
}

.passedVerification {
  margin-bottom: 50px;
  font-weight: bold;
  line-height: 48px;
  font-size: 18px;
  span {
    color: $color-blue;
  }
}

.claims {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  button {
    margin: 10px 0px 10px;
  }
  span {
    font-weight: bold;
    color: $color-blue;
  }
}

.verified {
  p {
    font-weight: bold;
    color: $color-blue;
    line-height: 48px;
    font-size: 18px;
  }
}