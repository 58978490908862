.deleteButton {
  cursor: pointer;
}

.deleteModal {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 7px 18px;
  z-index: 5;
  right: 56px;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  .deleteModalTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }

  .deleteModalButtons {
    display: flex;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    justify-content: space-between;
  }

  .triangle {
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -9px;
    left: 40%;
  }
}

.confirmButton {
  color: #E12F75;
}

.rejectButton {
  color: #149B58;
}


