@import '../../../../assets/style/colors';
@import "../../../../assets/style/variables";

.container {
  display: flex;
  background: #FCFBFE;
  position: relative;
  // margin-top: $top-margin;
  margin-left: 190px;
  transition: margin 350ms;

  &.collapsed {
    margin-left: 48px;
    transition: margin 350ms;
  }
}

.main {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 65px;
}

.scroll {
  width: 6px;
  background: #db902c;
  height: 443px;
}

