@import "../../../assets/style/variables";

.formatCell {
  width: 50px;
}

.project-invoices {
  position: relative;
}

.project-invoices-table {
  & > div {
    min-width: unset !important;
  }
  position: relative;
  .formatCell {
    width: 100%;
    text-align: center!important;
  }
  * [class*="last_format_cell"] {
    padding-right: 60px;
  }
  .formatHeader {
    text-align: center;
  }
  * [class*="last_format_header"] {
    padding-right: 60px;
  }
}

.project-invoices-xlxs {
  position: absolute;
  bottom: 22px;
  right: 19px;
  background: $color-white;
  border: 1px solid $blue;
  box-shadow: 0px 5px 15px $box-shadow-btn-outline;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  color: $blue;
  padding: 4px 16px;
  &:hover {
    border: 1px solid transparent;
  }
}

.invoices-download {
  position: absolute;
  top: -52%;
  right: 0;
}
