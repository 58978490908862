@import '../../../../assets/style/colors';
@import '../../../../assets/style/variables';
@import '../../../../assets/style/mixins';

.feedback-wrapper {
  background: $white;
  border: 1px solid $light-grey;
  border-radius: 14px;
  margin: 20px 0;
  padding: 16px 24px;

  .feedback-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-bottom: 9px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $light-grey;
    }
  }

  .feedback-info-wrapper {
    display: flex;
    align-items: center;
  }

  .feedback-info-img {
    margin-right: 14px;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }

  .feedback-info-text {
    text-align: left;
  }

  .feedback-info-name {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $dark-grey;
  }

  .feedback-info-rating {
    margin: 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: $grey;

    span {
      color: $points_icon;
    }
  }

  .feedback-info-task {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $dark-grey;
    margin: 0;

    span {
      font-weight: 700;
    }
  }

  .feedback-review {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }

  .feedback-positive{
    color: $green;
  }

  .feedback-negative{
    color: $red;
  }

  .feedback-right-column {
    text-align: right;
  }

  .feedback-date {
    margin-top: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $grey;
  }

  .feedback-text {
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }
}
