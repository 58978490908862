@import '../../../../../../../assets/style/colors';

.status-bar{
    margin-top: 22px;
    display: flex;
    align-items:center;
    width: 100%;
    height: 8px;
    .progress{
        position: relative;
        height: 8px;
        border-radius:8px;
        &::before{
            content:'';
            top: 0;
            right: 0;
            position: absolute;
            width:12px;
            height: 12px;
            background-color:$blue;
            border-radius:100%;
            transform: translate(50%,-2px);
            z-index: 2;
        }
        &::after{
            content:'';
            top: 0;
            right: 0;
            position: absolute;
            width:34px;
            height: 34px;
            background: radial-gradient(50% 50% at 50% 50%, #3737ed33 29.69%, #3737ed00 91.15%);
            border-radius:100%;
            transform: translate(50%,-14px);
            z-index: 1;
           
        }
    }
    
    .last{
        flex-grow: 1;
        height: 2px;
    }
}

.green{
    .progress{
        background: linear-gradient(270deg, #149b58cc 58.85%, #149b5800 100%);
    }
    .last{
        background-color:$red;
    }
}

.red{ 
    .progress{
        background:linear-gradient(to right,#f08fb500,#d12a6ccc);
    }
    .last{
        background-color:$scroll-bar-meter;
    }         
}