@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';


.container{
  margin-top: 40px;
  height: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .text {
    height: 100%;
    width: 60%;
    text-align: left;
    iframe {
      margin-top: 40px;
      width: 100%;
      height: 100%;
    }
  }

  .loader {
    position: absolute;
    display: flex;
    z-index: 20;
    background: rgba(0, 0, 0, 0.2);
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    align-items: center;
    // margin-right: 20px;
    // border-radius: 20px 20px 10px 10px;
  
    div {
      position: sticky;
      top: 170px;
    }
  }
}

h2 {
  margin: 0px;
}

p {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 0px 24px;
}