@import '../../../../assets/style/colors';
@import "../../../../assets/style/variables";
@import '../../../../assets/style/mixins';

.container {
  @include container;
  position: relative;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .content-container {
    display: flex;
    justify-content: space-between;
  }
}

.activities {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;

  .buttons {
    display: flex;
    align-items: center;
  }

  .history {
    color: $blue;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    background: none;
    margin-right: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;

    i {
      display: block;
      margin-right: 6px;
      svg {
        width: 13px;
      height: 13px;
      }
    }
  }
}

.filters {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 14px;
  margin-top: 16px;
  padding: 16px 24px;
}

.table {
  margin-top: 16px;
}

.table {
  [role="table"] {
    &>div>div {
      justify-content: flex-start;
    }
  }
}
