@import '../../../../../assets/style/colors';

.notification{

    &-container{
        padding: 0 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 93px;
        text-align: start;
        border-bottom: 1px solid $light-grey;
        cursor: pointer;
        &:hover{
            background-color:$light-grey-2;
        }
    }

    &-wrapper{
        display: flex;
    }

    &-avatar{
        margin-right: 12px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }
    &-title{
        width: 220px;
        font-size: 15px;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;  
    }

    &-message{
        width: 220px;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &-date{
        font-size: 12px;
        color:#9A9A9A
    }

}