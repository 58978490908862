@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.main {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    min-width: 1120px;
    background: $color-white;
    border: 1px solid $light-grey;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    padding: 20px 15px;

    .information {
      overflow: hidden;
      min-width: 510px;
      max-width: 550px;
      display: flex;
      font-size: 12px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        display: block;
        height: 176px;
        width: 1px;
        background: #EBEBEB;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .personal-info {
        .img-placeholder{
          width: 130px;
          height: 130px;
          margin: 15px;
        }
        img {
          width: 130px;
          height: 130px;
          background-blend-mode: normal;
          box-shadow: 0 0 20px rgba(179, 179, 248, 0.2);
          border-radius: 50%;
          margin: 13px $size-md*2 24px 0;
        }
        .socials {
          display: flex;
          justify-content: space-evenly;
          box-sizing: border-box;
        }
      }

      .description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        width: 340px;
        .description-info{
          margin-bottom: 11px;
          color: $dark-grey;
          span {
            display: block;
            width: 100%;
          }
          .description-name{
            max-width: 99%;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
            font-size: 30px;
            line-height: 25px;
            margin-bottom: 8px;
            margin-top: 33px;
            line-height: 41px;
          }
          .description-position{
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .main-info {
      padding-left: 30px;
      padding-right: 20px;
      display: flex;
      min-width: 340px;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      .main-info-item {
        display: flex;
        overflow: hidden;
        height: 24px;
        align-items: center;
        .main-info-logo {
          width: 15px;
        }
        .main-info-text {
          display: block;
          font-size: 14px;
          line-height: 24px;
          margin-left: 8px;
          width: calc(100% - 25px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: start;
        }
        .text-placeholder{
          flex-grow: 1;
          height: 24px;
        }
      }
    }

    .mood-scale-container {
      position: relative;
      width: 361px;
      .mood-scale {
        display: flex;
        flex-direction: column;
        width: 100%;
        h2 {
          font-size: 16px;
          line-height: 26px;
          margin-top: 0;
          margin-bottom: 15px;
          width: 160px;
        }
        .row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
        .mood-scale-cap {
          border: 1px solid $color-light-grey;
          min-width: 240px;
          max-width: 85%;
          height: 139px;
          border-radius: 6px;
          background: url("../../../assets/images/mood-scale-cap.png") no-repeat center center;
        }
        .mood-scale-cap-placeholder{
          min-width: 240px;
          max-width: 85%;
          height: 139px;
          border-radius: 6px;
        }
      }

      .deactivated {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      .deactivated-row {
        display: flex;
        align-items: center;
        padding-left: 5px;
        span {
          color: $red;
          font-weight: 700;
          font-size: 16px;
          line-height: 26px;
          margin-right: 8px;
        }
      }
      .question-icon {
        display: flex;
        justify-content: center;
        width: 16px;
        height: 16px;
        border: 1px solid $blue;
        border-radius: 50%;
        font-size: 12px;
        cursor: pointer;
        &:hover {
          box-shadow: 0 0 10px rgba(16, 16, 152, 0.4);
        }
      }
    }


    .points {
      display: flex;
      width: 40%;
      margin-left: 20px;
      background: $color-white;
      border: 1px solid $light-grey;
      box-sizing: border-box;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
      border-radius: 14px;
      padding: 24px;
      min-width: 550px;
      .points-icon{
        margin-right: 15px;
      }

      .points-value-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .points-value {
          font-weight: bold;
          font-size: $font-size-sm*3;
          line-height: 57px;
          text-align: center;
        }
      }

      img {
        border-radius: 50%;
      }
    }

    .send-btn {
      height: 48px;
      background: linear-gradient(180deg, #1F1FEB 0%, #3737ED 100%);
      box-shadow: 0 5px 15px rgba(31, 31, 235, 0.25);
      border-radius: 14px;
      font-weight: 600;
      font-size: $font-size-md;
      line-height: 22px;
      text-align: center;
      color: $white;
      outline: none;
      border-style: none;
    }

    .send-btn:hover {
      background: $blue;
      box-shadow: 0 5px 10px rgba(55, 55, 237, 0.4);
      transition: color 0.2s;
    }

    .send-btn:active {
      background: linear-gradient(180deg, #101098 0%, #1F1FEB 100%);
      box-shadow: 0 0 10px rgba(16, 16, 152, 0.4);
    }

    .rating-btn {
      height: 48px;
      background: $color-white;
      border: 1px solid $blue;
      box-sizing: border-box;
      box-shadow: 0 5px 15px rgba(179, 179, 248, 0.15);
      border-radius: 14px;
      font-weight: 600;
      font-size: $font-size-md;
      line-height: 22px;
      text-align: center;
      color: $blue;
      outline: none;
    }

    .rating-btn:hover {
      background: $blue;
      box-shadow: 0 5px 10px rgba(55, 55, 237, 0.4);
      color: $white;
      transition: color 0.2s;
    }

    .rating-btn:active {
      background: linear-gradient(180deg, #101098 0%, #1F1FEB 100%);
      box-shadow: 0 0 10px rgba(16, 16, 152, 0.4);
      color: $white;

    }
  }

  .deactivated-profile {
    border: 1px solid $red!important;
  }
