@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.react-calendar {
  background: $color-white;
  border: 1px solid $color-light-grey;
  border-radius: 10px;
  min-height: 290px;
  padding: 24px 24px 0px 24px;
  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    .react-calendar__navigation__arrow {
      font-size: 30px;
      color: $blue;
      font-weight: bold;
    }
    .react-calendar__navigation__label__labelText {
      font-size: 20px;
      color: $dark-grey;
      font-weight: bold;
    }
  }
  .react-calendar__viewContainer {
    .react-calendar__tile {
      padding: 10px;
      cursor: pointer;
    }
    .react-calendar__tile--active {
      color: $white;
      background: $blue;
      border-radius: 50%;

    }
    abbr {
      font-size: 14px;
    }
  }
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button,
.react-calendar__month-view__weekdays {
  display: none !important;
}

