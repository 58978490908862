@import "../../../../../assets/style/variables";
@import '../../../../../assets/style/colors';

.container {
  position: absolute;
  right: 76px;
  top: 20px;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 10;
  cursor: pointer;
}
.menu {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  position: absolute;
  z-index: 11;
  top: 52px;
  left: 8px;
  width: 163px;
  min-height: 39px;
  transform: translateX(-50%);

  background: $white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;

  &:before {
    content: '';
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-bottom-color: $white;

    width: 10px;
    height: 10px;
  }

  & li {
    width: 100%;
    padding: 10px 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    color: $dark-grey;
  }

  & li:hover {
    background-color: #EFEFFF;
  }

  & li:first-child {
    margin: 0;
  }
}
.edit-btn{
  cursor: pointer;
}

.archived-sign {
  position: absolute;
  font-weight: 700;
  font-size: 14px;
  color: $color-crimson;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  right: 52px;
}
