@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.info-section, .filesSection {
  @include profile-info-section;

  .info-container {
    display: flex;
    justify-content: center;
    padding-top: 26px;
    padding-bottom: 8px;
    text-align: left;
    justify-content: space-between;

    div:first-of-type {
      position: relative;
      width: 50%;
      margin-right: 32px;
      box-sizing: content-box;
      border-right: 1px solid $light-grey;
    }

    div:last-of-type {
      width: 50%;
      padding-right: 32px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
      color: $color-black;
      margin: 0;
      margin-bottom: 28px;
      text-align: justify;
      padding-right: 32px;

      span {
        color: $blue;
        font-weight: bold;
      }
    }

    .placeholder{
      width: calc(100% - 32px);
      margin-bottom: 28px;
      line-height: 24px;
    }

    .children-birth-day-field {
      color: $grey;
      font-weight: 400;
    }
  }
}

.filesSection {
  margin-top: 28px;
  text-align: left;
    a {
      color: $dark-grey;
    }
}
