@import '../../../../../assets/style/colors';

.container{
    position: relative;
    padding: 5px 10px 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 25px;
    border-radius: 10px;
    border:1px solid white;
    cursor: pointer;

    &:hover{
        background-color: $light-grey-2
    }

    .user-avatar{
            width: 50px;
            height: 50px;
        }


    .notification-title{
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        width: calc(100% - 15% - 100px);
        font-size: 16px;
        text-align: start;

        &-bold{
            font-weight:500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &-light{
            color:gray;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .notification-date{
        width: 15%;
        color:gray;
        font-size:13px;
    }

    .notification-label{
        position: absolute;
        left: 6px;
        width: 10px;
        height: 10px;
        background-color:$primary-color;
        border-radius:100%;
    }

}

.active{
    background-color: $light-grey-2;
    border:1px solid $light-grey;
}

.viewed{
    opacity: 0.7;
}

.new{
    border:1px solid $light-grey;
}