@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.container {
    @include container;
    padding: 0px;
    .header {
      @include top-header;
      h1{
        @include top-header-h1
      }
    }
    
    margin: 15px 0px 25px;
    color: $dark-grey;
    
    .personal-info {
      @include profile-info-section;

      text-align: left;
      p {
        font-size: 14px !important;
      }

      .about-task {
        line-height: 24px;
        color: $blue;
        font-weight: bold;
      }
    }
}

.wrap-bar {
    position: relative;
    top: 16px;
    margin-bottom: 30px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    .back-btn {
        font-size: 14px;
        cursor: pointer;
        color: $blue;
        svg {
            position: relative;
            right: 5px;
            top: 3px;
        }
    }
}

.avatar {
  height: 40px;
  width: 40px;
}

.info-task {
  line-height: 24px;
  position: relative;
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 10px;
  .title-user {
    color: $grey;
    font-weight: bold;
  }
}

.response-status-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.response-status{
  display: flex;
  justify-content: center;
  align-items:center;
  width: 130px;
  height: 32px;
  border-radius:8px;
  background-color: $green;
  font-size: 14px;
  font-weight: bold;
  color:white;
  text-transform: capitalize;
}

.user-info {
  left: 50px;
  top: 0px;
  position: absolute;
  p {
    margin: 0;
  }
}

.responser-desc {
  display: flex;
  p {
    font-weight: 700;
    font-size: 14px;
    color: $green;
    margin-right: 36px;
    span {
      color: $dark-grey;
    }
  }
}

.light-btn {
  height: 32px;
  width: 210px !important;
  background: $white;
  border-radius: 14px;
  box-shadow: 0px 5px 15px rgba(55, 55, 237, 0.1);
  border: 1px solid $blue;
  color: $blue;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 0 15px;
  transition: all 0.2s;
  margin-right: 20px;

  &:hover {
    background: $blue-hover;
    color: $white;
    border-color: $blue-hover;
    cursor: pointer;
  }
}

.take-btn {
  height: 32px;
  width: 130px !important;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  padding: 0px;
}

.pop-num {
  position: relative;
  right: 34px;
  top: -5px;
  border-radius: 100%;
  background-color: $green;
  width: 14px;
  height: 14px;
  text-align: center;
  p {
    width: 14px;
    height: 14px;
    margin: 0;
    color: $white;
    font-size: 10px;
    line-height: 14px;
  }
}

.response-date {
  color: $grey;

}
