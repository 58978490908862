@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 5px;
  min-width: 220px;
  min-height: 80px;
}

.label {
  max-width: max-content;
  font-size: 12px;
  color: $color-gray;
}

.input {
  height: 30px;
  width: 160px;
  margin: 5px 0;
  padding-left: 10px;
  border: 1px solid $color-blue;
  border-radius: 10px;

  &:disabled {
    border-color: $color-gray;
  }
}

.errorMessage {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 600;
  color: $red;
}