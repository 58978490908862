@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';
.container {
    @include container;
    .header {
      @include top-header;
      h1{
        @include top-header-h1
      }
    }
    margin: 20px 0px 25px;
}

.content{
    margin:  0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1120px;
}

.list{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.empty-list{
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  align-items: center;
  height:calc(100vh - 200px);

  .text{
    width: 300px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }
}