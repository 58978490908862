@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';


.positionsInfoSection {
  margin-top: 20px;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.tableContainer {
  overflow-y: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    text-align: right;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #f5f5f5;
  }
}