@import "../../../assets/style/variables";

.currency-input {
  div[class*="wrapper"] {
    &::before {
      content: '$';
      position: absolute;
      font-size: 12px;
      color: $color-gray;
      top: 50%;
      transform: translateY(-50%);
      left: 11px;
    }
    input {
      padding-left: 22px;
    }
  }
}

.time-input {
  div[class*="wrapper"] {
    &::before {
      content: url('../../../assets/images/time_icon.svg');
      position: absolute;
      height: 12px;
      width: 12px;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
    input {
      padding-right: 32px;
    }
  }
}

.team-archive-table {
  & > div {
    min-width: unset !important;
  }
  position: relative;
  .formatCell {
    width: 100%;
    text-align: center!important;
  }
  * [class*="last_format_cell"] {
    padding-right: 60px;
  }
  .formatHeader {
    text-align: center;
  }
  * [class*="last_format_header"] {
    padding-right: 60px;
  }
}

.team-archive-xlxs {
  position: absolute;
  bottom: 22px;
  right: 19px;
  background: $color-white;
  border: 1px solid $blue;
  box-shadow: 0px 5px 15px $box-shadow-btn-outline;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  color: $blue;
  padding: 4px 16px;
  &:hover {
    border: 1px solid transparent;
  }
}
