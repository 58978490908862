@import '../../../../assets/style/colors';
@import "../../../../assets/style/variables";

.download {
  margin-right: 32px;
}

.button {
  cursor: pointer;

  div {
    left: auto;
    right: -49px;
    top: 40px;
  }
}

.buttons-wrapper {
  display: flex;
}

.save {
  padding: 3px 0;
  width: 58px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: $blue;
  border: 1px solid $blue;
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(55, 55, 237, 0.1);
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  bottom: 0;
  right: -789px;
}
