@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';

.filterBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profitDesc {
  font-size: 16px;
  font-weight: 700;
}

.profitAmount {
  color: $green;
}

.graphWrapper {
  margin-bottom: 50px;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}