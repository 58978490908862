@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.total-feedbacks {
  text-align: left;
  padding-left: 10px;
  margin-top: 38px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: $dark-grey;
}

.toggle {
  text-align: left;
}
