@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.container {
  position: relative;
  color: $color-dark-blue;
}

.form {
  width: 100%;
  padding: 34px 40px 32px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  background: $color-white;
}

.form-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
}

.div-wrapper {
  display: flex;
  justify-content: space-between;
  width: 177px;

  button {
    background-color: inherit;
    outline: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }
}

.div-feecharger-wrapper {
  display: flex;
  justify-content: space-between;
  width: 110px;

  button {
    background-color: inherit;
    outline: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }
}

.input-wrapper {
  width: 120px;
  margin: 0 12px 0;
}

.input-feecharger-wrapper {
  display: none;
}

.feecharger {
  font-weight: 600;
  font-size: 20px;
  color: $invalid-red;
}

.input-label {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 17px;
  color: $dark-grey;
}

.achievements-amount {

  .input-label {
    font-size: 12px;
    padding-bottom: 7px;
    color: $grey;
  }
}

.form-row-btn {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.form-row-btn .div-btn-wrapper {
  width: 150px;
}

.achievements-container {
  width: 30%;
}

.pointsFund-container {
  width: 30%;
}

.row-btn {
  position: absolute;
  right: 0;
  top: -52px;
  display: inline-block;
  width: max-content;
  text-align: right;
  padding-right: 30px;
}

.row-btn .btn-wrapper {
  width: 210px;
  margin-left: 30px;
}