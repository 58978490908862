@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.info-section {
  @include profile-info-section;

  .info-container {
    width: 100%;
    padding-top: 26px;
    padding-bottom: 8px;
    text-align: left;
  }
}