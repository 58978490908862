@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import '../../../assets/style/mixins';

.container {
  @include container;
  position: relative;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .content-container {
    display: flex;
    justify-content: space-between;
  }
}

.history-changes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.history-button {
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: none;
  margin-right: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;

  i {
    display: block;
    margin-right: 6px;
    svg {
      width: 13px;
    height: 13px;
    }
  }
}

.filter {
  margin-top: 20px;
}

.filter form div {
  justify-content: flex-start !important;

  div {
    margin-bottom: 0 !important;
  }
}

.table {
  margin-top: 16px;
  div[data-for="model"] {
    overflow: auto;
    position: relative;

    div[role="table"] {
      width: 150% !important;
      margin-bottom: 0 !important;

      div[role="row"] {
        padding: 0 32px;
      }
    }
  }
}
