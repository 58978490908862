@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import '../../../../../assets/style/mixins';

.container {
  @include container;
  position: relative;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .block-go-back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    font-size: 12px;
    margin-top: 26px;
    padding-bottom: 26px;
  }

  .go-back {
    background: none;
    color: $blue;
    padding: 0;
    cursor: pointer;
    svg {
      margin-bottom: -2.5px;
    }
    svg path {
      stroke: $blue;
    }
    .go-back-text {
      margin-left: 8px;
      font-size: 14px;
    }
  }

  .filter {
    margin-top: 20px;
  }

  .accordion-wrapper {
    background: $white;
    border: 1px solid #EBEBEB;
    box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
    border-radius: 14px;
    padding-top: 12px;
  }

  .accordion-top-header {
    padding: 0 0 12px 25px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $light-grey;
    }
  }

  .accordion-container {
    max-height: 600px;
    overflow: auto;
  }

  .accordion-top-header-item {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: $blue;
    display: flex;
    justify-content: flex-start;
    width: 100%;

  }

  .accordion-header {
    width: 100%;
    padding: 16px 0 16px 25px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: #F7F7FF;
    }

    &.active {
      background-color: #F7F7FF;
    }
  }

  .accordion-header-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    &:first-child {
      font-weight: 700;
    }
  }

  .arrow {
    position: absolute;
    right: 25px;
  }

  .accordion-content {
    padding-top: 16px;
    padding-left: 24px;
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
    &.active {
      background-color: #F7F7FF;
    }
  }

  .accordion-field {
    text-align: left;

  }

  .accordion-mini-title {
    color: $blue;
    font-weight: 700;
  }

  .accordion-changes {
    margin-top: 17px;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  .accordion-before {
    width: 35%;
    margin-right: 7.25rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: -40px;
      bottom: 0;
      width: 1px;
      height: 100%;
      background-color: $light-grey;
    }
  }

  .accordion-after {
    width: 35%;
  }

  .difference {
    background: rgba(225, 47, 117, 0.15);
    border-radius: 1px; /* Цвет для выделения отличающихся символов */
  }

  .empty {
    min-height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
