@import '../../assets/style/colors';
@import "../../assets/style/variables";

.filter-input {
  input {
      display: flex;
      width: 60px;
      height: 30px;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid $blue;
      min-width: 80px;
      box-sizing: border-box;
      border-radius: 10px;
      background: $color-white;
      box-shadow: 0 0 20px $box-shadow;
      color: $dark-grey;
      margin: 5px 10px 0 0;
      padding: 5px 5px 5px 5px;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
  }
}

.request-timeoff-comments {
    width: 100%;

  input {
    width: 100%;
    height: 150px;
    border: 1px solid $blue;
    border-radius: 10px;
    box-shadow: 0 0 20px $box-shadow;
    color: $dark-grey;
    padding: 5px 5px 5px 5px;
    background-color: #fff;
  }
}
