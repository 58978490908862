@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";

.active-name{
  &>div:nth-child(1) {
    color: $grey
  }
}

.active-level {
  &>div:nth-child(3) {
    color: $grey
  }
}


