@import '../../../../assets/style/colors';
@import '../../../../assets/style/variables';
@import '../../../../assets/style/mixins';

.container {
  @include container;
  position: relative;
  margin-top: 20px;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .user_idcard_photo {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;
    grid-template-columns: 200px 200px;
    gap: 15px;
    div {
      border-radius: 10px;
      border: 1px solid $blue-hover;
      cursor: pointer;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;

    .inputs {
      display: flex;
      flex-direction: column;
      justify-content: start;
      text-align: left;

      .age-input {
        width: 168px;
        box-sizing: border-box;
        input {
          width: 90px;
        }
      }
    }
  }

  .photo-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-self: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
    z-index: 999;
    transition: all 500ms;
    align-items: center;
    cursor: pointer;

    img {
      width: max-content;
      height: max-content;
    }
  }

  .loadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding-bottom: 30vh
  }
}

.verification {
  margin-top: 20px;
}

.pasport {
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
  position: relative;
  .passport_up {
      position: absolute;
      opacity: 0.2;
      height: 275px;
      width: 455px;
  }
  .named {
      position: absolute;
      top: -60px;
  }
}

.add-image-arria {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 275px;
  width: 455px;
  border: dashed 1px $blue;
  background: #EBEBEB;
  border-radius: 8px;
  cursor: pointer;
  label {
    cursor: pointer;
    p {
      left: 0px;
    }
    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
  .delete {
      position: absolute;
      bottom: -25px;
  }
}