@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.wrapper {
  margin-right: 28px;
  .checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-size: $font-size-sm;
    color: $dark-grey;
  }
  ::before {
    content: '';
    width: 16px;
    height: 16px;
    border: 1px solid $grey-disabled;
    border-radius: 3px;
    margin-right: 9px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 80%;
  }
}
.checkbox:checked+label::before {
  border-color: $blue;
  background-image: url("../../../assets/images/check_mark_icon.svg");
}
.checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: $blue;
}

