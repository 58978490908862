@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.root {
    height: 2px !important;
    color: $green !important;

    .track {
        color: $red;
    }
    
    .rail {
        opacity: 1 !important;
    }
    
    .thumb {
        width: 12px !important;
        height: 12px !important;
        border-radius: 50%;
        background: conic-gradient($green 0deg 180deg, $red 180deg 360deg);
        box-shadow: none !important;
    
        &:hover, &:focus, &.active, &.focusVisible {
            box-shadow: none !important;
        }
    }
    
}

.sliderContainer {
    padding: 0 0 20px;
  
    .sliderContainerValues {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }
  
    .sliderValueCurrent {
      color: $red;
    }
    
    .sliderValueMax {
      color: $green;
    }
  }
  


