@import '../../assets/style/variables';

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  margin: 0 12px;
  color: $dark-grey;
  text-decoration: none;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.selected {
  color: $white;
  background-color: $green;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}
