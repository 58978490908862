@import-normalize;
@import '../../assets/style/_colors.scss';
@import "../../assets/style/variables";

iframe {
    body {
      #webpack-dev-server-client-overlay-div {
        display: none !important;
      }
    }
}

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // min-width: 1360px;
}

select, input {
  outline: none;
}

button,
button:active,
button:hover {
  outline: 0;
  border-style: none;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

ul {
  padding: 0;
  list-style-type: none;
}

// style for React DatePicker
.react-datepicker-wrapper{
  width: 100%
}
.react-datepicker__input-container input {
  border: 1px solid $blue;
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: $color-white;
  box-shadow: 0 0 20px $box-shadow;
  color: $dark-grey;
  margin: 4px 10px 0 0;
  padding: 6px 5px 4px 5px;
  appearance: none;
  background: url(../../assets/images/calendar.svg) no-repeat;
  background-position: calc(100% - 5px);
  outline: none;
  font-size: 12px;
}

.react-datepicker__input-container input[disabled] {
  border: 1px solid #aaa;
  cursor: not-allowed;
  background-image: url(../../assets/images/calendar_inactive.svg);
}

.react-datepicker {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
  border-radius: 6px;
  border: none;
}

.react-datepicker__navigation:hover {
  border-style: solid;
}

.react-datepicker__header {
background-color: #fff;
border-bottom: none;
border-top-left-radius: 6px;
border-top-right-radius: 6px;
}

.react-datepicker__day-name {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin: 2px;
  color: #9A9A9A;
}

.react-datepicker__day{
margin: 2px;
border-radius: 0;
font-weight: normal !important;
}

.react-datepicker__day:hover{
background: $green;
border-radius: 50%;
}

.react-datepicker__day--disabled:hover {
background: $white;
}

.react-datepicker__day--selected:hover {
background: $green;
border-radius: 50%;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background: $green !important;
  border-radius: 50% !important;
  color: #FFFFFF !important;
}

.react-datepicker__day--in-selecting-range {
background: $Light-gray-range-datepicker;
color: $black;
}

.react-datepicker__day--in-selecting-range:hover {
background: $green;
border-radius: 50%;
color: $white;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
color: white;
}

.react-datepicker__day--in-range {
background-color: #8EF0C0;
color: #000000;
border-radius: 50%;
}

.react-datepicker__header__dropdown{
  display: flex;
  justify-content: space-around;
}

.react-datepicker__current-month{
  margin-bottom: 10px;
}

.react-datepicker__month-read-view{
  font-size: 14px;
}
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown{
  position: absolute;
  background: $white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
  border-radius: 6px;
  border: none;
  width: 40%;
}
.react-datepicker__month-dropdown{
  left: 15px;
}
.react-datepicker__year-dropdown{
  left: 134px;
  width: 35%;
}

// styles for toastr

#toast-container > .toast {
  background: $white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  width: 280px;
  color: $dark-grey;
  background-repeat: no-repeat;
  background-position: 7% 50%;
  padding-left: 65px;

  .toast-close-button {
    font-size: 0;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    top: -9px;
    right: -9px;
  }

  .toast-message {
    padding-right: 10px;
  }
}

#toast-container > .toast-info[aria-live='polite'] {
  background-image: url(../../assets/images/toastr_info-icon.svg) !important;
  border: 1px solid $blue;
  .toast-close-button {
    background-image:  url(../../assets/images/toastr_btn_info-icon.svg);
    }
}

#toast-container > .toast-error[aria-live='assertive'] {
  background-image: url(../../assets/images/toastr_error-icon.svg) !important;
  border: 1px solid $require-error-color;
  .toast-close-button {
    background-image:  url(../../assets/images/toastr_btn_error-icon.svg);
  }
}

#toast-container > .toast-success[aria-live='polite'] {
  background-image: url(../../assets/images/toastr_success-icon.svg) !important;
  border: 1px solid $green;
  .toast-close-button {
    background-image:  url(../../assets/images/toastr_btn_success-icon.svg);
  }
}
// styles for scrollbar

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-track {
  background: $scroll-bar-background;
  box-shadow: inset 0 0 3px rgba(179, 179, 248, 0.2);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: $scroll-bar-thumb;
  border-radius: 5px;
  width: 6px;
  height: 64px;
}

// styles for pagination

.pagination {
  display: flex;
}

[data-for^="to"] .react-datepicker-popper {
  transform: translate3d(1281px, 42px, 0px) !important;

  .react-datepicker__triangle {
    margin-left: 97px !important;
  }
}
