@import "../../assets/style/variables";
@import '../../assets/style/colors';

.container {
  width: $header-width;
  height: 70px;
  margin: -$top-margin auto 0 auto;
  display: flex;
  justify-content: flex-end;
  color: $color-black;
  position: fixed;
  z-index: 1;
  padding: 12px 35px 12px 20px;
  background: $white;
  box-shadow: 0 0 20px $box-shadow;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &.sidebar_collapsed {
    width: $header-width-sidebar-collapsed;
  }

  .points {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    line-height: 19px;

    .subcontract_balance {
      display: flex;
      align-items: center;

      .subcontract_balance_item {
        margin-right: 12px;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        white-space: nowrap;

        span {
          color: $green;
          font-weight: 700;
          padding-left: 5px;
        }
      }
    }

    .points_bell {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: $header-icons-height;
      width: $header-icons-width;
      margin: $header-icons-margin;

      .popup_notif {
        position: absolute;
        text-align: center;
        width: 16px;
        height: 16px;
        font-size: 10px;
        color: #FFFFFF;
        background: #E12F75;
        border-radius: 50%;
        top: 1px;
        right: 1px;
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }

    .header_menu {
      padding-top: 5px;
      position: relative;
      bottom: 3px;
      margin: 0 10px;
    }

    .points_trl {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: $header-icons-margin;

      button {
        background: $green;
      }

      .points_balance {
        display: inline-flex;
        justify-content: left;
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        margin-top: 4px;
        color: $green;
        font-weight: 600;

        span {
          padding-left: 5px;
        }
      }

      .points_currency {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #9A9A9A;
      }

    }

    &>ul {
      width: $header-icons-width;
      height: $header-icons-height;
      border-radius: 12px;
      margin-right: 15px;
      margin-top: 0;
      margin-bottom: 0;
      margin: $header-icons-margin;
      padding-left: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: white;
      padding-left: 5px;
      padding-top: 5px;
      // position: fixed;
      // top: 14px;
      // right: 220px;

      li {

        span {
          .popup-icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            position: relative;
            cursor: pointer;


            .counter {
              position: absolute;
              text-align: center;
              width: 16px;
              height: 16px;
              font-size: 10px;
              color: #FFFFFF;
              background: #E12F75;
              border-radius: 50%;
              top: -5px;
              right: -8px;
              align-items: center;
              display: flex;
              justify-content: center;
            }
          }

          svg path {
            fill: $blue;
          }
        }

        span.is-disabled {
          pointer-events: none;
          cursor: default;

          svg path {
            fill: $grey;
          }

          .popup-icon {
            .counter {
              background: $grey;
            }
          }
        }
      }
    }
  }

  .popup-active {
    z-index: 100;
  }

  .popup-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    padding: 24px 0;
    width: 340px;
    height: 477px;
    background: $white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    z-index: 99;

    &::before {
      content: '';
      position: absolute;
      top: -20px;
      width: 40px;
      height: 20px;
      background: url("../../assets/images/basket-arrow.svg") no-repeat;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: $scroll-bar-background;
      box-shadow: inset 0 0 3px rgba(179, 179, 248, 0.2);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: $scroll-bar-thumb;
      border-radius: 5px;
      width: 6px;
      height: 64px;
    }

    .popup-header {
      padding: 0 34px;
      display: flex;
      justify-content: space-between;

      h2 {
        display: block;
        font-style: normal;
        font-weight: bold;
        font-size: $font-size-sm;
        line-height: 19px;
        color: $color-dark-blue;
        margin: 0 0 10px 0;
      }

      svg {
        display: block;
      }

    }

    .popup-content {
      width: 100%;
      height: 300px;
      overflow: auto;
    }

    .popup-footer {
      padding: 0 34px;
      border-top: 1px solid $color-light-grey;
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-sm;
      line-height: 26px;
      color: $dark-grey;
      display: flex;
      flex-direction: column;

      .popup-total-amount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
      }

      .popup-total-amount p:last-child {
        font-size: 18px;
        color: $blue;
        display: flex;
        align-items: center;
        line-height: 18px;

        i {
          margin: 0;

          svg {
            margin-left: 10px;
            width: $size-md;
            height: $size-md;

            rect {
              stroke: $blue;
            }

            path {
              fill: $blue;
            }
          }
        }
      }

      a,
      .popup-btn {
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-md;
        line-height: 22px;
        padding: $size-sm $size-sm*5.5;
        color: $color-white;
        text-decoration: none;
        background: linear-gradient(180deg, #1F1FEB 0%, #3737ED 100%);
        box-shadow: 0px 5px 15px rgba(31, 31, 235, 0.25);
        border-radius: 14px;
      }

      .popup-btn-not-enough {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: inherit;
      }

      .popup-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
      }
    }
  }

  .popup-container-right {
    top: 85px;
    right: 92px;

    &::before {
      right: 146px;
    }

    .popup-footer {
      padding-top: 24px;
    }

    .popup-content {
      width: 100%;
      height: 330px;
      overflow: auto;
    }
  }

  .popup-container-left {
    top: 85px;
    right: 145px;

    &::before {
      right: 143px;
    }

  }

  .popup-close {
    cursor: pointer;
  }

  .admin-notification {
    right: 10px;

    &::before {
      right: 68px;
    }

  }


  .counter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-sm;
    line-height: 19px;
    text-align: center;
    color: $color-black;

    button {
      margin: 0;
      padding: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;

      svg {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
      }
    }

    button:disabled svg rect {
      fill: $color-gray;
    }

    button:disabled svg circle {
      stroke: $color-gray;
    }
  }

  .mask {
    width: 200vw;
    height: 100vh;
    position: fixed;
    background: #00000090;
    z-index: 98;
    transform: scale(2);
    overflow: hidden;

  }
}

.redirect-modal {
  min-height: 0 !important;
  padding: 16px 16px 32px;
  max-width: 360px;

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: fit-content;
    }
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    padding: 0 42px;
  }

  .required-info-image {
    width: 276px;
    height: 251px;
    background: url('../../assets/images/requiredInfoPopup.svg');
    margin: 40px auto 57px;
  }
}

.loader {
  position: absolute;
  display: flex;
  z-index: 20;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  width: 202vh;
  height: 100vh;
  top: 0px;
  right: 0px;
  align-items: center;
}
