@import '../../assets/style/colors';
@import '../../assets/style/variables';

.btn-primary {
  background: linear-gradient(180deg, $ultra-blue 0%, $blue 100%);
  box-shadow: 0 5px 15px $box-shadow-btn;
  border-radius: 10px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: $white;
  outline: none;
  border-style: none;
}

.btn-primary:hover {
  background: $blue;
  box-shadow: 0 4px 10px rgba(55, 55, 237, 0.25);
  transition: color 0.3s;
  outline: none;
}

.btn-primary:active {
  background: linear-gradient(180deg, #101098 0%, #1F1FEB 100%);
  box-shadow: 0 0 8px rgba(16, 16, 152, 0.4);
  outline: none;
}

.btn-primary:disabled {
  background: linear-gradient(180deg, rgba(16, 16, 152, 0.4) 0%, rgba(16, 16, 152, 0.4) 100%);
  box-shadow: 0 0 8px rgba(16, 16, 152, 0.4);
  outline: none;
}

.btn-primary-outline {
  height: 40px;
  background: $color-white;
  border: 1px solid $blue;
  box-sizing: border-box;
  box-shadow: 0 5px 15px rgba(179, 179, 248, 0.15);
  border-radius: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: $blue;
  outline: none;
}

.btn-primary-outline:hover {
  background: $blue;
  box-shadow: 0 5px 10px rgba(55, 55, 237, 0.4);
  color: $white;
  transition: color 0.2s;
}

.btn-primary-outline:active {
  background: linear-gradient(180deg, #101098 0%, #1F1FEB 100%);
  box-shadow: 0 0 10px rgba(16, 16, 152, 0.4);
  color: $white;
}

.btn-link {
  background: none;
  border: none;
  outline-style: none;
  text-align: center;
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.btn-link:active {
  outline: none;
}

.btn-discharge {
  height: 30px;
  margin-top: 18px;
  margin-right: $size-sm;
  padding: 0;
  border: none;
  color: inherit;
  background-color: transparent;
  outline: none;
}

.btn-discharge:hover svg path {
  fill: $discharge-btn-hover;
}

.btn-discharge:hover svg circle {
  fill: $discharge-btn-hover;
}

.btn-discharge:active svg path {
  fill: $discharge-btn-active;
}

.btn-discharge:active svg circle {
  fill: $discharge-btn-active;
}


.humburger-btn {
  margin-left: 40px;
  border-style: none;
  background: none;
  outline-style: none;
}

.btn-import,
.btn-request {
  background: linear-gradient(180deg, #1F1FEB 0%, #3737ED 100%);
  box-shadow: 0px 5px 15px rgba(31, 31, 235, 0.25);
  border-radius: 10px;
}

.btn-request {
  width: 150px;
  height: 30px;
}

.btn-import {
  width: 100px;
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  color: $white;
}