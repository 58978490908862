@import "../../../assets/style/colors";
@import "../../../assets/style/variables";

.root {
  height: 1px !important;

  .track {
    height: 3px !important;
    background: linear-gradient(90deg, #fff 0%, $red 80%);
    border: none;
  }

  .rail {
    color: $scroll-bar-meter;
    opacity: 1 !important;
  }

  .thumb {
    border-radius: 50%;
    position: relative;
    width: 7px;
    height: 7px;
    color: $blue !important;

    &::after {
      content: "";
      position: absolute;
      opacity: 0.15 !important;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background: radial-gradient(
        circle,
        rgba(0, 30, 255, 0) 15%,
        #fff 20%,
        $blue 50%,
        rgba($blue, 0.3) 70%,
        #fff 80%
      );
    }

    &:hover,
    &:focus,
    &.active, 
    &.focusVisible {
      box-shadow: none !important;
    }
  }
}

.sliderContainer {
  padding: 0 0 20px;

  .sliderContainerValues {
    display: flex;
    justify-content: start;
    font-weight: bold;
  }

  .sliderValueCurrent {
    color: $red;
  }

  .sliderValueMax {
    color: $green;
  }
}
