@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.container {
  .timeButtons {
    display: flex;
    flex-wrap: wrap;

    .time {
      flex: 0 0 25%;
      font-size: 12px;
      line-height: 18px;
      button {
        width: 72px;
        height: 26px;
        margin-bottom: 10px;
        border-radius: 10px;
        cursor: pointer;
      }
      &.available button {
        color: $blue;
        border: 1px solid $blue;
        background: $light-blue;
      }
      &.busy button {
        color: $grey;
        border: 1px solid $grey;
        cursor: not-allowed;
      }
      &.active button {
        color: $white;
        border: 1px solid $blue;
        background: $blue;
      }
    }
  }
}
