.api {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
    h1 {
      margin-bottom: 10px;
    }
    .description {
      width: 570px;
      margin-bottom: 0px;
      font-size: 14px;
      line-height: 24px;
    }
    .how-get {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #3737ED;
        margin-bottom: 32px;
        margin-top: 1px;
        cursor: pointer;
    }
    // .methods {
    //   display: flex;
    //   justify-content: center;
    //   flex-direction: row;
    //   margin-bottom: 50px;
    //   div {
    //     width: 360px;
    //     height: 180px;
    //     margin: 0px 20px;
    //     text-align: center;
    //     border-radius: 10px;
    //     border: 1px solid #EBEBEB;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     // box-sizing: border-box;
    //   }
    // }
    .inputs {
        text-align: left;
        width: 360px;
        form {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }
  }

  .how-get-key-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .main-title {
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      color: #333333;
    }

    .steps {
      display: flex;
      justify-content: center;
      align-items: center;

      .line {
        width: 73px;
        height: 1px;
        border: 1px solid #149B58;
      }

      .action {
        border: 1px solid #149B58 !important;
        background-color: #149B58 !important;

        span {
          color: #fff !important;
        }
      }

      .step {
        background-color: #fff;
        border: 1px solid #149B58;
        height: 29px;
        width: 29px;
        text-align: center;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          color: #149B58;
        }
      }
    }

    .about {
      p {
        width: 50%;
        margin: 24px auto 48px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
      }
    }

    .btn {
      width: 360px;
      display: flex;
      justify-content: space-between;
    }

  }