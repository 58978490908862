@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';


.container {
  @include container;
  display: flex;
  padding-top: 20px;
}

.actionButtonsWrapper {
  display: flex;
  width: max-content;
  gap: 20px;
}

.header {
  @include top-header;

  h1 {
    @include top-header-h1
  }
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.task {
  min-width: 1120px;
  background: $color-white;
  border: 1px solid $light-grey;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  text-align: left;
  padding: 28px 32px 40px;

  h2 {
    font-size: $font-size-lg;
    line-height: 28px;
    margin: 0;
    color: $dark-grey;
  }

  .line {
    margin: 20px 0;
    height: 2px;
    background: $light-grey;
  }

  .description {
    display: flex;

    span {
      font-size: $font-size-sm;
      color: $grey;
      font-weight: bold;
    }

    .performer {
      flex-grow: 1;

      .info {
        display: flex;

        .avatar {
          width: 50px;
          height: 50px;
          position: relative;
          right: 5px;
        }

        .title {
          .reviews {
            font-size: $font-size-xs;
            color: $dark-grey;
            line-height: 18px;
            font-weight: 600;

            span {
              font-size: $font-size-xs;
              font-weight: 600;
            }

            :nth-child(1) {
              color: $red;
            }

            :nth-child(2) {
              color: $green;
            }
          }
        }
      }
    }

    .amount {
      flex-grow: 1;

      h2 {
        color: $green;
      }
    }

    .term {
      flex-grow: 1;
    }

    .info,
    .total,
    .period {
      margin-top: 8px;
    }
  }
}

.buttons {
  min-width: 1120px;
  display: flex;
  margin-top: 32px;

  .actionsButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
}

.buttonAction {
  display: flex;
  width: 360px;
  justify-content: space-between;
}