@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";

.dateInvalid {
  border: 1px solid $require-error-color !important;
}

.tooltip{
  background-color: $require-error-color !important;
  border-radius: 6px;
  padding: 3px 12px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  z-index: 0;
  visibility: visible;
  opacity: 1;
  left: 500px;
  top: 150px;
  color: $white;
  border: 1px solid $light-grey;
  box-shadow: 0 0 20px $box-shadow;
  margin-top: 15px !important;
  &::after {
    border-bottom-color: $require-error-color !important;
  }
}


