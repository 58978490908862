@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";

.input-name {
  width: 50%;
}

.label-for-field {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  color: $color-gray;
}

.red-note {
  padding-left: 5px;
  display: inline;
  color: $invalid-red
}

.input {
  margin-top: 7px;
}
