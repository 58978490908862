@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.container {
    @include container;
    .header {
      @include top-header;
      h1{
        @include top-header-h1
      }
    }
    margin: 20px 0 25px;
    text-align: start;
}

.content{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 1120px;
}


.button{
    padding: 4px 20px;
    font-size: 14px;
    line-height: 24px;
    height: fit-content;
}

.block{
    padding:20px 24px;
    display: flex;
    flex-direction: column;
    background: $white;
    border:1px solid $light-grey;
    border-radius:14px;
    font-size: 18px;
    font-weight: 500;
    box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);

    .header{
        display: flex;
        justify-content: space-between;
        align-items:center;
    }
}

.subtitle{
    font-size: 20px;
}

.desc{
    row-gap: 20px;

    &-body{
        display: flex;
        column-gap: 30px;
    }

    &-footer{
        padding-top:16px ;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 2fr 1fr;
        border-top: 1px solid $light-grey;
        gap: 8px;
    }
}

.category-wrapper{
    display: flex;
    justify-content: space-between;
    column-gap:16px;
}

.category{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 8px;
}

.category-label{
    font-size: 14px;
    color:$grey;
}

.category-row {
    flex-direction: row;
    align-items: center;
}

.value,.name{
    display: flex;
    align-items:center;
    font-size: 20px;
    font-weight: 600;
    height: 40px;
    font-family: $font-family;
}

.value-price{
    color:$green;
}

.value-green{
    color:$green;
}

.value-red {
    color:$pale-red;
}

.avatar{
    width: 40px;
    height: 40px;
    border-radius:100%
}

.avatar-wrapper{
    display: flex;
    align-items:center;
    column-gap:12px;
}

.votingWrapper{
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap:20px;
}

.comments{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.comment{
    cursor: pointer;
}

.comment-desc{
    margin: 20px 0 24px;
    font-size: 14px;
    font-weight: 400;
}

.budget{
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap:30px;
    .title{
        margin: 0;
        width: 100%;
        font-size: 16px;
    }
}

.executor,.customer{
    display: flex;
    align-items: center;
    column-gap:4px;
    .value{
       font-size: 14px;
       font-weight: 700;
       height: fit-content;
    }

}

.voting{
    height: fit-content;
    .executor,.customer{
        justify-content: space-between;
        flex-wrap: wrap;
    }
}



.executor{
    .value{
        color:$green;
    }
}

.customer{
    .value{
        color:$red;
    }
}

.percents{
    margin: 30px 0 23px;
    display: flex;
    flex-direction: column;
    row-gap: 43px;
}

.modal{
    width: 100%;
    max-width: 616px;
    min-height: 0;
}

.modal-content{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    .subtitle{
        text-align: center;
        font-size: 20px;
    }

    .comment-desc{
        margin: 16px 0 0;
    }
}

.radio-group{
    display: flex;
    flex-direction: column;
    row-gap: 70px;

    .root{
        font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
}

.button.button-content {
    width: max-content;
}
