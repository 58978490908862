// @import '../assets/style/colors';
// @import '../assets/style/variables';
// @import '../assets/style/mixins';

.btn {
    position: fixed; 
    width: 50px;
    right: 5%;
    bottom: 40px;
    height: 50px;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.4s; 
    transition: all 1s;
    background: #fff ;
    color: #3737ED;
    box-shadow: none;
    border: 1.5px solid #3737ED;
    border-radius: 10px;
    &:hover {
        background: #3737ED ;
        color: #fff;
    }
    @keyframes fadeIn {
       0% { opacity: 0; }
       100% { opacity: 1; }
    }
}
