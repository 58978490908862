@import '../../../../assets/style/colors';
@import '../../../../assets/style/variables';
@import '../../../../assets/style/mixins';

.container {
  @include container;
  margin: 15px 0px 25px;


  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .mainInfo {
    @include profile-info-section;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 24px 32px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
  }

  .backButton {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 34px 0;
    color: #3737ED;
    cursor: pointer;
    font-weight: 600;
  }

  .vacancyInfo {

  }

  .vacancyTitle {
    display: flex;
    align-items: center;
    gap: 16px;
    h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      letter-spacing: 0.01em;
    }
    div {
      height: 21px;
    }
  }

  .vacancy__info-wrap {
    display: flex;
    margin-top: 40px;
  }

  .vacancy__info-list {
    display: flex;
    flex-direction: column;
    gap: 28px;
    text-align: left;
    &:first-child {
      padding-right: 67px;
      border-right: 1px solid #EBEBEB;
    }
    &:last-child {
      margin-left: 56px;
    }

    li {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      span {
        color: #3737ED;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .vacancy__info-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    word-wrap: break-word;
    span {
      color: #3737ED;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .vacancy__info-border {
    padding-right: 67px;
    border-right: 1px solid #EBEBEB;
  }

  .vacancy__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    margin-top: 24px;
  }

  .mainInfoTop {
    display: flex;
    justify-content: space-between;
  }

  .vacancy__list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 28px;
    min-width: 50%;
    &:last-child {
      padding-left: 64px;
      border-left: 1px solid #EBEBEB ;
    }
  }

}
