@import "../../assets/style/variables";
@import "../../assets/style/colors";

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 5px 15px $box-shadow-btn;
  width: 60px;
  height: 60px;
  background-color: $color-white;

  img {
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-btn {
  width: 30px;
  height: 30px;

  img {
    width: 25px;
    height: 25px;
  }
}

.spinnerWrap {
  position: absolute;
  margin-left: -190px;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
}