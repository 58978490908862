@import '../../assets/style/colors';
@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.personal-info {
  @include profile-info-section;
  text-align: left;
}

.title {
  margin: 0;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 20px;
}

.description {
  margin: 0;
}

.infoDetailsWrapper {
  display: flex;

  p {
    width: 50%;
    margin: 0;
  }
}

.about-task {
  margin: 0;

  &--color {
    color: $blue;
    font-weight: 700;
  }
}

.infoDetailsWrapper,
.description,
.about-task {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.about-task:last-child {
  margin-bottom: 0;
}