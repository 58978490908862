@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import '../../../assets/style/mixins';

.container {
  // width: 96px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.text {
  margin: 0px 6px 0px 6px;
  color: $blue;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.toggle-button {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin: 0;
  vertical-align: top;
  background: #9A9A9A;
  border: 1px solid #bbc1e1;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.toggle-button::after {
  content: "";

  display: inline-block;
  position: absolute;
  left: 1px;
  top: 1px;

  width: 18px;
  height: 18px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;

  transform: translateX(0);
  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.toggle-button:checked::after {
  transform: translateX(calc(100% + -1px));
  background-color: #fff;
}

.toggle-button:checked {
  background-color: blue;

  &:disabled {
    background-color: $color-gray;
  }
}

.reverse {
  flex-direction: row-reverse;
}

.inactive {
  color: $color-gray;
}