@import "../../../../assets/style/variables";
@import '../../../../assets/style/colors';

.container {
  position: relative;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 10000;
}

.avatar {
  cursor: pointer;
  z-index: 98;
  width: $size-md*2;
  height: $size-md*2;
  border-radius: 50%;
  background-blend-mode: normal;
  box-shadow: 0 0 $size-md rgba(179, 179, 248, 0.2);
  .avatar {
    width: inherit;
    height: inherit;
    border-radius: 50%;
  }
}

.menu {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  position: absolute;
  z-index: 10001;
  top: 55px;
  left:20px;
  width: 95px;
  min-height: 35px;
  transform: translateX(-50%);

  background: $white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  // &_admin {
  //   top: 55px;
  // }

  &:before {
    content: '';
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-bottom-color: $white;

    width: 10px;
    height: 10px;
  }

  & li {
    width: 100%;
    padding: 10px 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    color: $dark-grey;
  }

  & li:hover {
    background-color: #EFEFFF;
  }

  & li:first-child {
    margin: 0;
  }
}
