@import '../../../../../../../assets/style/colors';
@import '../../../../../../../assets/style/variables';
@import '../../../../../../../assets/style/mixins';

.pasport {
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
    position: relative;
    .passport_up {
        position: absolute;
        opacity: 0.2;
        height: 275px;
        width: 455px;
    }
    .named {
        position: absolute;
        top: -60px;
    }
}

.add-image-arria {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    width: 455px;
    border: dashed 1px $blue;
    background: #EBEBEB;
    border-radius: 8px;
    cursor: pointer;
    label {
      cursor: pointer;
      p {
        left: 0px;
      }
      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
    }
    .delete {
        position: absolute;
        bottom: -25px;
    }
}

.claimsqr {
  // margin-top: 20px;
  margin-top: 50px;
  align-items: center;
  display: flex;
  height: 500px;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.spinner {
  display: flex;
  justify-content: center;
  padding-top: 200px;
}

.denied {
  font-weight: 700;
  font-size: 24px;
}