@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.container {
  @include container;
  position: relative;
  color: $dark-grey;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
}
.container-flex {
  display: flex;

  justify-content: space-between;
  padding: 10px 0;
  align-items: flex-start;
}

.notification-list{
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 780px;
    height: 630px;
    background: $white;
    border: 1px solid $light-grey;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    overflow: auto;

}

.block-go-back{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 20px;
  position: relative;
  top: 10px
}

.go-back{
  position: static;
  width: 73px;
  height: 25px;
  left: 24px;
  top: 0px;
  background: none;
  color:$blue;
  padding: 0;
  cursor: pointer;
  svg{
    height: 18px;
    width: 18px;
    margin-bottom: -2.5px;
  }
  svg path{
    stroke: $blue;
  }
  .go-back-text{
    margin-left: 8px;
    font-size: 16px;
  }
}

.empty-list{
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  align-items: center;
  height: 100%;

  .text{
    width: 200px;
    font-weight: bold;
    font-size: 20px;
  }
}