@import '../../../../assets/style/colors';
@import "../../../../assets/style/variables";

.container {
  box-sizing: border-box;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 auto;
    position: relative;
    width: 265px;
    height: 270px;
    background: rgba(55, 55, 237, 0.8);
    mix-blend-mode: multiply;
    border-radius: 14px;
    overflow: hidden;
  border: 1px solid $grey-disabled
  }

.price{
    display: flex;
    align-items: center;
    justify-content: space-around;

    position: absolute;

    width: 78px;
    height: 32px;

    font-style: normal;
    font-weight: bold;
    font-size: $font-size-sm;
    line-height: 19px;
    color: $blue;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 0px 12px 0px 14px;
    z-index: 2;
    .logo{
      fill: $blue;
      float: right;
    }
  }

.people {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    width: 53px;
    height: 32px;
    background: $blue;
    mix-blend-mode: normal;
    border-radius:  12px 0 14px 0;
    color: $blue;
    background: rgba(255, 255, 255, 0.85);
    font-weight: bold;
    font-size: $font-size-sm;
    line-height: 19px;
    z-index: 2;

    .people_icon{
        margin-left: 8px;
        svg{
          width: 13px;
          height: 15px;
          path {
            fill: $blue;
          }
        }
    }
}

.descr{
  width: 265px;
  height: 271px;
  left: 123px;
  top: 225px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 20px $box-shadow;
  border-radius: 14px;
  transform: translate3d(0, 192px, 0);
  transition: all 0.25s linear;
  cursor: pointer;
}

.btn{
  position: absolute;
  width: 80%;
  height: 30px;
  left: 23px;
  top: 201px;
  background: linear-gradient(180deg, $ultra-blue 0%, $blue 100%);
  box-shadow: 0px 5px 15px $box-shadow-btn;
  border-radius: 10px;
  border-color: $ultra-blue;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-xs;
  line-height: 16px;
  text-align: center;
  color: $color-white;
  cursor: pointer;
}

.name{
  position: absolute;
  left: 5px;
  width: 255px;
  height: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: $color-dark-blue;
  text-align: center;
  transition: all 0.25s linear;
  text-align: start;
  padding: 17px;
}

.container:hover {
  box-shadow: 0 0 20px rgba(51,51,51, 0.4);
  border-color: rgba(255, 255, 255, 0.75);
}

.container:hover .descr{
  transform: translate3d(0, 0, 0);
}

.container:hover .price{
  background: $blue;
  color: $color-white;
}

.container:hover .people{
  background: $blue;
  color: $color-white;
  .people_icon{
    margin-left: 8px;
    svg path{
      fill: $color-white;
      stroke: $color-white;
    }
  }
}

.container:hover .name{
  top: 100px;
}

.container:hover .logo{
  svg {
    path{
      fill: white;
    }
  }
}
