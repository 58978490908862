@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.55);
  z-index: 999;
  transition: all 500ms;
  cursor: pointer;
  overflow-y: scroll;
  max-height: 100vh;
}

.hidden {
  left: -9999px;
}

.container {
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  visibility: hidden;
  opacity: 0;
  max-width: 670px;
  padding-bottom: 56px;

  &.active {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }

  h2 {
    @include mini-title;
    text-align: center;
    margin: 0;
  }

  .modal {
    padding: 32px 25px;

    .form {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      text-align: left;

      .amount {
        input {
          width: 200px;
        }
      }

      .price {
        font-weight: bold;
        color: $dark-grey;
        line-height: 30px;

        span:nth-child(2) {
          color: $green;
        }
      }

      .message {
        flex-grow: 1;

        textarea {
          margin: 0;
        }
      }

      .label {
        font-size: $font-size-xs;
        color: $grey;
        margin-bottom: 4px;
      }

      .check {
        margin-top: 14px;
      }

      &.cancel-task-form {
        flex-direction: column;
        align-items: initial;
      }
    }

    .line {
      margin: 16px 0;
      height: 2px;
      background: $light-grey;
    }

    .buttons {
      margin-top: 46px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;
    }

    .buttons-suggest {
      margin-top: 78px;
      justify-content: center;

      button {
        margin: 0 10px;
      }
    }

    .buttons-cancel {
      justify-content: center;

      button {
        margin: 0 10px;
      }
    }

    &.modal-size-default {
      min-width: 460px;
    }

    &.modal-size-md {
      min-width: 616px;
    }
  }
}

.LoadingModal {
  min-height: 0;
  padding: 10px 32px 24px;

  p {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }

  .success .btn {
    width: fit-content;
    margin: 0 auto;

    button {
      width: 172px !important;
      font-size: $font-size-sm;
    }
  }
}

.range {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description {
  max-width: 310px;
}

.splitSumContainer {
  display: flex;
  justify-content: space-between;
}

.splitSumWrapper {
  width: 150px;
}

.splitSumDescr {
  max-width: 310px;
}

.controllerDescription {
  max-width: 310px;
  color: $dark-grey;
  font-size: 12px;
  text-align: left;
}

.subTitle {
  font-size: 16px;
  font-weight: 600;
  color: $dark-grey;
  text-align: left;
}

.offer {
  &__title {
    text-align: left;
  }


  &__count {
    margin-top: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  &__line {
    display: inline-block;
    margin: 0 10px;
    height: 16px;
    width: 1px;
    background-color: #BBBBFF;
  }

  &__info {
    text-align: left;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: 1px;
      background-color: #BBBBFF;
    }
  }

  &__info--wrapper {
    display: flex;
    gap: 33px;
    padding: 10px;
    padding-left: 32px;
    padding-right: 32px;

    &:nth-child(2n + 1) {
      background: #F9F9FF;
    }
  }

  &__info-item {
    font-size: 11px;
    width: 50%;
    line-height: 14px;
  }

  &__table {
    display: grid;
    grid-template-columns: 0.5fr 1.2fr 0.6fr 0.8fr 1.3fr;
    background: #F9F9FF;
    font-size: 11px;
    border-bottom: 1px solid #BBBBFF;
  }

  &__column {
    border: 1px solid #BBBBFF;
    border-collapse: collapse;
    min-height: 34px;
    padding: 10px;
  }

  &__column-title {
    font-weight: 700;
    padding: 10px;
  }

  &__statement {
    p {
      margin: 0;
      font-size: 8px;
    }

    p+p {
      margin-top: 15px;
    }

    text-align: left;
    padding: 0 40px;
    margin-top: 20px;
    font-size: 11px;
    margin-top: 20px;
  }

  &__contractor {
    margin-top: 20px;
    margin-bottom: 240px;
  }

  &__contractor--name {
    display: block;
    margin-top: 4px;
  }
}

.overlayHidden {
  left: -9999px;
}

.hidden {
  left: -9999px;
}