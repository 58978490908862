@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.info-section, .filesSection {
  @include profile-info-section;
  cursor: pointer;

  .user-info {
      display: flex;
        justify-content: space-between;
    .avatar {
      width: 78px;
      height: 78px;
      margin-right: 5px;
      border-radius: 100%;
    }
    .user {
        display: flex;
        .user-desc {
            text-align: left;
            padding-top: 10px;
            margin-bottom: 25px;
            h2 {
                margin-bottom: 4px;
                color: $blue;
            }
            p {
                margin: 4px 0px;
            }
            .score {
                font-weight: 700;
                font-size: 12px;
                color: $grey;
                span {
                    color: $points_icon;
                }
            }
        }
    }
    button {
        width: 160px;
        height: 32px;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        padding: 0px;
        border-radius: 10px;
        // height: 32px;
    }
  }
  .description {
    display: flex;
    background-color: #fff;
    line-height: 24px;
    text-align: left;
    justify-content: space-between;
    span {
      color: $dark-grey;
      font-weight: 700;
      font-size: 14px;
    }
    font-weight: 400;
    font-size: 14px;
  }

}

.onetask {
  text-decoration: none;
  color: $dark-grey;
}
