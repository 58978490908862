@import '../../../../../../assets/style/colors';
@import '../../../../../../assets/style/variables';
@import '../../../../../../assets/style/mixins';

.container {
    border: 1px solid #EBEBEB;
    border-radius: 10px;
    flex-grow: 1;
}

.modal {
    position: absolute;
    z-index: 1001;
    background-color: #FFFFFF;
    height: 452px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 616px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 31px 24px 24px 24px;
    border-radius: 20px;
    h2 {
        margin: 0px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: $dark-grey;
    }
    .title, .description {
        text-align: left;
        width: 100%;
        margin-bottom: 15px;
    }
    .modalLine {
        border: 1px solid $light-grey
    }
    svg {
        height: 16px;
        width: 16px;
        margin-right: 8px;
    }
    .timeSlot {
        display: flex;
        flex-direction: column;
        margin-bottom: 29px;
        margin-top: 20px;
    }
    p {
        margin: 0px;
        color: $dark-grey;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }
}

.mask {
    width: 200vw;
    height: 200vh;
    position: fixed;
    background: #00000090;
    z-index: 1000;
    -webkit-transform: scale(2);
    transform: scale(2);
    overflow: hidden;
}

.days {
    display: flex;
    flex-direction: column;
    .day-week {
        color: #9A9A9A !important;
        font-weight: 400 !important;
        font-size: 10px !important;
        line-height: 14px !important;
    }

    .day-month {
        color: #3737ED !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 24px !important;
    }

    .today {
        border-radius: 100%;
        background-color: #3737ED;
        .day-month {
            color: #fff !important;
        }
    }
}

.days > div {
    display: flex;
    justify-content: center;
    width: 28px;
    height: 28px;
    align-items: center;
}

.timeText {
  display: flex;
  align-items: center;

  p {
    left: 0;
    padding-top: 0;
  }

  i {
    display: flex;
  }
}

.buttons {
  margin-top: 30px;

  button:first-child {
    margin-right: 20px;
  }

  button {
    cursor: pointer;
    min-width: 200px;
  }
}

.modalIcons {
  display: flex;
  align-items: center;
  position: relative;
}

.modalButton {
  padding: 0;
  margin-right: 0;
  cursor: pointer;
  &:nth-child(1) {
    margin-right: 20px;
  }
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 0;
    }
  }
}

.modalClose {
  position: absolute;
  right: 0;
  top: -2px;

  i {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.modalText {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
  margin-top: 24px !important;

  span {
    color: $blue;
    font-weight: 700;
  }

}

.modalDescription {
  margin: 20px 0 !important;

  span {
    display: block;
  }
}

.validationError {
  color: $invalid-red;
  display: block;
  margin: 10px 0;
}

.slotTitle {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

.slotTime {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

